import produce from "immer";
import { CronActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { CLEAR_CRON, CLEAR_CRON_STATE, FETCH_CRON_LIST_FAILED, FETCH_CRON_LIST_PROGRESS, FETCH_CRON_LIST_SUCCESS } from "./cronActions";
import { initialCron, initialCronState } from "./defaultState";



export const cronReducer = (
  state: IStoreState["cron"] = initialCronState,
  action: CronActions,
) => {
  switch (action.type) {
    case FETCH_CRON_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CRON_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CRON_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case CLEAR_CRON: {
      const newState = produce(state, (draftState) => {
        draftState.singleData.loading = LoadState.NotLoaded;
        draftState.singleData.data = initialCron;
      });
      return newState;
    }
    case CLEAR_CRON_STATE: {
      return initialCronState;
    }
    default: {
      return state;
    }
  }
};
