import { ICustomer } from "./customer/customer.types";
import { ICustomizerState } from "./customizer/customizerReducer";
import { IMessagesState } from "./messages/messages.types";
import { initialMessagesState } from "./messages/messagesState";
// import { IAutomobilePolicyRiskLocationState, defaultAutomobilePolicyRiskLocationState } from "./automobilePolicyRiskLocation/automobilePolicyRiskLocation.types";
import { ICommonState, defaultCommonState } from "./common/common.types";

import { IUsersState } from "./UserProfileList/userProfile.types";
import { initialUserProfileState } from "./UserProfileList/userprofileState";

import { IArtistState } from "./artist/artist.types";
import { initialArtistState } from "./artist/defaultState";
import { initialCustomerState } from "./customer/customerState";
import { ICategoryState } from "./category/category.types";
import { initialCategoryState } from "./category/defaultState";
import { defaultDataManagementState } from "./DataManagement/defaultState";
import { IDataManagementState } from "./DataManagement/dataManagement.types";
import { initialPlaylistState } from "./playlist/defaultState";
import { IPlaylistState } from "./playlist/playlist.types";
import { initialCronState } from "./cron/defaultState";
import { ICronState } from "./cron/cron.types";
import { initialSecurityState, ISecurityState } from "./security/security.types";

export interface IDropdownListType {
  key: string;
  text: string;
  value: string;
}

export interface IStoreState {
  customizerReducer: ICustomizerState;
  customer: ICustomer;
  message: IMessagesState;
  userprofile: IUsersState;
  common: ICommonState;
  artist: IArtistState;
  category: ICategoryState;
  dataManagement: IDataManagementState;
  playlist: IPlaylistState;
  cron: ICronState;

  // security
  security: ISecurityState;
}

export const initialStoreState: IStoreState = {
  customizerReducer: {
    activeDir: "ltr",
    activeNavbarBg: "#0b70fb", // This can be any color,
    activeSidebarBg: "#ffffff", // This can be any color
    activeMode: "light", // This can be light or dark
    activeTheme: "RED_THEME", // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME, RED_THEME
    SidebarWidth: 240,
    sidebarOpen: true,
  },

  customer: initialCustomerState,
  message: initialMessagesState,
  common: defaultCommonState,
  userprofile: initialUserProfileState,
  artist: initialArtistState,
  category: initialCategoryState,
  dataManagement: defaultDataManagementState,
  playlist: initialPlaylistState,
  cron: initialCronState,

  // security
  security: initialSecurityState,

};
