import { LoadState } from "../../constants/enums";
import { IArtist, IArtistProfile, IArtistState } from "./artist.types";

export const initialArtist: IArtist = {
    artist_id: "",
    artist_name: "",
    channel_id: "",
    status: "",
}

export const initialArtistList: IArtistProfile = {
    artist_id: "",
    channel_id: null,
    channel_name: null,
    artist_name: null,
    artist_small_img: null,
    artist_medium_img: null,
    artist_large_img: null,
    artist_xlarge_img: null,
    artist_xxlarge_img: null,
    artist_slug: null,
    status: "",
}

export const initialArtistState: IArtistState = {
    list: {
        data: [],
        totalRecords: 0,
        loading: LoadState.NotLoaded,
        error: null
    },
    singleArtist: {
        data: initialArtist,
        loading: LoadState.NotLoaded,
        error: null
    }, 
    artistProfile: {
        data: initialArtistList,
        loading: LoadState.NotLoaded,
        error: null
    }
}