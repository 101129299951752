import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import { showMessage } from "../messages/messagesActions";
import { IPlaylist } from "./playlist.types";

export const FETCH_PLAYLIST_LIST_PROGRESS = "FETCH_PLAYLIST_LIST_PROGRESS";
export const FETCH_PLAYLIST_LIST_SUCCESS = "FETCH_PLAYLIST_LIST_SUCCESS";
export const FETCH_PLAYLIST_LIST_FAILED = "FETCH_PLAYLIST_LIST_FAILED";

export const fetchPlaylistListProgress = () =>
  action(FETCH_PLAYLIST_LIST_PROGRESS);
export const fetchPlaylistListSuccess = (
  data: IPlaylist[],
  totalRecords: number,
) => action(FETCH_PLAYLIST_LIST_SUCCESS, { data, totalRecords });
export const fetchPlaylistListFailed = () => action(FETCH_PLAYLIST_LIST_FAILED);

export const fetchPlaylistListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchPlaylistListProgress());
        const searchQuery = getSearchQuery(queryParams);
        const res = await api.get(`/playlist/get-playlist${searchQuery}`);
        const data: IPlaylist[] = res.data.data;

        const totalRecords = res.data.totalRecords;

        dispatch(fetchPlaylistListSuccess(data, totalRecords));
      } catch (err: any) {
        dispatch(fetchPlaylistListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const upsertPlaylistAsync =
  (
    data: Partial<IPlaylist>,
    onCallback: (isSuccess: boolean, Playlist?: IPlaylist) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const { create_ts, insert_ts, rowId, ...rest } = data;
        const res = await api.put("/playlist/update-final-video-data", rest);
        let message = "Playlist saved successfully!";
        onCallback(true, res.data.data);
        dispatch(
          showMessage({
            type: "success",
            displayAs: "snackbar",
            message: message,
          }),
        );
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const FETCH_PLAYLIST_PROGRESS = "FETCH_PLAYLIST_PROGRESS";
export const FETCH_PLAYLIST_SUCCESS = "FETCH_PLAYLIST_SUCCESS";
export const FETCH_PLAYLIST_FAILED = "FETCH_PLAYLIST_FAILED";

export const fetchPlaylistProgress = () => action(FETCH_PLAYLIST_PROGRESS);
export const fetchPlaylistSuccess = (data: IPlaylist) =>
  action(FETCH_PLAYLIST_SUCCESS, { data });
export const fetchPlaylistFailed = (errorMessage: string) =>
  action(FETCH_PLAYLIST_FAILED, { errorMessage });

export const fetchPlaylistAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchPlaylistProgress());
        const res = await api.get(
          `/playlist/get-playlist?artist_id=${uuid}`,
        );
        const data: IPlaylist[] = res.data.data;
        if (data.length > 0) {
          dispatch(fetchPlaylistSuccess(data[0]));
        } else {
          dispatch(fetchPlaylistFailed("Oops! We couldn't find any records."));
        }
      } catch (err: any) {
        dispatch(fetchPlaylistFailed("Something went to be wrong!"));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };


export const upsertBulkCategoriesAsync =
  (
    data: {
      categories_ids: string[],
      videos_ids: string[]
    },
    onCallback: (isSuccess: boolean, Playlist?: IPlaylist) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {

        const res = await api.put("/playlist/set-bulk-categories", data);
        let message = "Categories added successfully!";
        onCallback(true, res.data.data);
        dispatch(
          showMessage({
            type: "success",
            displayAs: "snackbar",
            message: message,
          }),
        );
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const CLEAR_PLAYLIST = "CLEAR_PLAYLIST";
export const clearPlaylist = () => action(CLEAR_PLAYLIST);
export const CLEAR_PLAYLIST_STATE = "CLEAR_PLAYLIST_STATE";
export const clearPlaylistState = () => action(CLEAR_PLAYLIST_STATE);
