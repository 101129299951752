import produce from "immer";
import { ArtistActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { CLEAR_ARTIST, CLEAR_ARTIST_STATE, FETCH_ARTIST_LIST_FAILED, FETCH_ARTIST_LIST_PROGRESS, FETCH_ARTIST_LIST_SUCCESS, FETCH_ARTIST_PROFILE_FAILED, FETCH_ARTIST_PROFILE_PROGRESS, FETCH_ARTIST_PROFILE_SUCCESS } from "./artistActions";
import { initialArtist, initialArtistState } from "./defaultState";


export const artistReducer = (
  state: IStoreState["artist"] = initialArtistState,
  action: ArtistActions,
) => {
  switch (action.type) {
    case FETCH_ARTIST_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_ARTIST_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_ARTIST_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_ARTIST_PROFILE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_ARTIST_PROFILE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.artistProfile.loading = LoadState.Loaded;
        draftState.artistProfile.data = data;
      });
      return newState;
    }
    case FETCH_ARTIST_PROFILE_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.artistProfile.loading = LoadState.InProgress;
      });
      return newState;
    }
    case CLEAR_ARTIST: {
      const newState = produce(state, (draftState) => {
        draftState.singleArtist.loading = LoadState.NotLoaded;
        draftState.singleArtist.data = initialArtist;
      });
      return newState;
    }
    case CLEAR_ARTIST_STATE: {
      return initialArtistState;
    }
    default: {
      return state;
    }
  }
};
