import { combineReducers } from "redux";
import { artistReducer } from "./artist/artistReducer";
import { categoryReducer } from "./category/categoryReducer";
import { commonReducer } from "./common/commonReducer";
import { cronReducer } from "./cron/cronReducer";
import { customerReducer } from "./customer/customerReducer";
import { customizerReducer } from "./customizer/customizerReducer";
import { dataManagementReducer } from "./DataManagement/dataManagementReducer";
import { IStoreState } from "./initialStoreState";
import { messageReducer } from "./messages/messagesReducer";
import { playlistReducer } from "./playlist/playlistReducer";
import { UserProfileReducer } from "./UserProfileList/userProfileReducer";
import { securityReducer } from "./security/securityReducer";


const appReducer = combineReducers({

  customizerReducer: customizerReducer,
  customer: customerReducer,
  message: messageReducer,
  userprofile: UserProfileReducer,
  common: commonReducer,
  artist: artistReducer,
  category: categoryReducer,
  dataManagement: dataManagementReducer,
  playlist: playlistReducer,
  cron: cronReducer,

  // security
  security: securityReducer,
});

export const rootReducer = (state: IStoreState | undefined, action: any) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
