export const INSURANCE_REF_DATA = {
  quotesFor: {
    "Auto Insurance": [
      "Automobile Insurance",
      "Commercial Automobile Insurance",
      "Motorcycle Insurance",
      "Bundle Insurance (Home+Auto)",
      "Classic Car Insurance",
      "Boat Insurance",
      "RV Insurance",
      "Private Client Insurance",
      "Snowmobile Insurance",
    ],
    "Home Insurance": [
      "Home Insurance",
      "Rental Property Insurance",
      "Tenant Insurance",
      "Condo Insurance",
      "Cottage Insurance",
      "Bundle Insurance (Home+Auto)",
    ],
    "Bundle Insurance (Home+Auto)": [
      "Auto + Home Insurance",
      "Auto + Condo Insurance",
      "Auto + Tenant Insurance",
    ],
    "Business Insurance": [
      "Account Receivable Insurance",
      "Commercial Automobile Insurance",
      "General Liability Insurance",
      "Employee Liability Insurance",
      "Product Liability Insurance",
      "Professional Liability Insurance",
      "Commercial Property Insurance",
      "Contractors Insurance",
      "Corporate Insurance",
      "Cyber Liability Insurance",
      "Environmental Impairment Liability Insurance",
      "Home Based Business Insurance",
      "Small Medium Business Insurance",
      "Legal Expense Insurance",
      "Malpractice Insurance",
      "Private Client Insurance",
      "Specialty Insurance",
      "Garage Insurance",
      "Builder's Risk Insurance",
    ],
    "Life & Financial": [
      "Life Insurance",
      "Critical Illness Insurance",
      "Disability Insurance",
      "Mortgage Insurance",
      "Travel Insurance",
      "Long Term Care Insurance",
      "Group Benefits",
      "Investments",
    ],
  },
  dbMaps: {
    "Auto Insurance": "auto_insurance_type",
    "Home Insurance": "home_insurance_type",
    "Bundle Insurance (Home+Auto)": "bundle_insurance_type",
    "Business Insurance": "business_insurance_type",
    "Life & Financial": "life_financial_type",
  },
};

export const contactTimes = [
  { text: "Anytime Contact You", value: 1 },
  { text: "7:00 am to 9:00 am", value: 2 },
  { text: "9:00 am to 11:30 am", value: 3 },
  { text: "11:30 am to 1:00 pm", value: 4 },
  { text: "1:00 pm to 3:00 pm", value: 5 },
  { text: "3:00 pm to 5:00 pm", value: 6 },
  { text: "5:00 pm to 8:00 pm", value: 7 },
];

export const lineofBusiness = {
  Business: {
    list: [
      "Business",
      "Automobile",
      "Habitational",
      "Commercial Auto",
      "Life & Financial",
    ],
    subTypes: [
      "Retail",
      "Wholesale",
      "Garage",
      "Manufacturer",
      "Contractor",
      "Professional Services",
      "Office",
      "Restaurant & Hospitality",
      "Errors & Omissions",
      "CMP",
      "Cyber Liability",
      "Environment Liability",
      "CGL",
    ],
  },
  automobile: {
    list: [
      "Business",
      "Automobile",
      "Habitational",
      "Commercial Auto",
      "Life & Financial",
    ],
    subTypes: [
      "Retail",
      "Wholesale",
      "Garage",
      "Manufacturer",
      "Contractor",
      "Professional Services",
      "Office",
      "Restaurant & Hospitality",
      "Errors & Omissions",
      "CMP",
      "Cyber Liability",
      "Environment Liability",
      "CGL",
    ],
  },
};

export const transactionTypes = [
  "New",
  "Rewrite/Remarket",
  "Renewal",
  "Reissue",
];

export const Template = [
  "Automobile",
  "Business",
  "Commercial Auto",
  "Habitational",
  "Life & Financial",
  "Farm",
  "Travel",
];

export const segment = [
  "Business & Professional Services",
  "Contractors",
  "Retail and Automotive",
  "Realty",
  "Hospitality and Restaurant",
  "Health Services",
  "Manufacturing/Processing",
  "Wholesale",
  "Warehousing",
  "Transportation",
  "Education",
  "Farming",
  "Government",
  "Member Organizations",
  "Mining",
  "Recreation",
  "Utilities",
];

export const typeOfPolicy = ["Individual", "Bordereau"];

export const policyStatus = [
  "Active",
  "Cancelled",
  "Lapsed",
  "New Submission",
  "Quote Received",
  "Sent to Insurer",
  "Waiting on Producer (Business and Commercial Auto Policy Only.)",
];

export const cancelType = ["Short-Rate", "Flat", "Pro-Rata"];

export const current_status = [
  "Declined by Insurer",
  "Non Renewed by Insurer",
  "Cancelled by Insured",
  "Coverage Not Required",
  "Remarketed on Insured Request",
];

export const payment_method = [
  "1 Non Payment Cancellation in the last 5 years",
  "More than 1 Non Payment Cancellation in the last 3 years",
  "More than 2 Non Payment Cancellation in the last 5 years",
  "No, Non Payment Cancellation in the last 3 years",
];

export const form_of_buisness = [
  { label: "Corporation", value: "Corporation" },
  { label: "Association", value: "Association" },
  { label: "Charity , Non-registered", value: "Charity , Non-registered" },
  { label: "Charity , Registered", value: "Charity , Registered" },
  { label: "Church", value: "Church" },
  { label: "City", value: "City" },
  { label: "Country", value: "Country" },
  { label: "Estate of", value: "Estate of" },
  { label: "For-Profit Organization", value: "For-Profit Organization" },
  { label: "Government Agency", value: "Government Agency" },
  { label: "Hospital", value: "Hospital" },
  { label: "Individual", value: "Individual" },
  { label: "Joint Venture", value: "Joint Venture" },
  { label: "Limited Liability Company", value: "Limited Liability Company" },
  { label: "Municipality", value: "Municipality" },
  { label: "Non-Profit Organization", value: "Non-Profit Organization" },
  { label: "Non Profit Association", value: "Non Profit Association" },
  { label: "Organization", value: "Organization" },
  { label: "Other - See Remarks", value: "Other - See Remarks" },
  {
    label: "Partnership or Limited Partnership",
    value: "Partnership or Limited Partnership",
  },
  { label: "Proprietorship", value: "Proprietorship" },
  { label: "Region", value: "Region" },
  { label: "School", value: "School" },
  { label: "School Board", value: "School Board" },
  { label: "Select One", value: "Select One" },
  { label: "Town", value: "Town" },
  { label: "Township", value: "Township" },
  { label: "Trust", value: "Trust" },
  { label: "Unicorporated Association", value: "Unicorporated Association" },
  { label: "Village", value: "Village" },
];

export const knownToBroker = [
  "1931",
  "1950",
  "1958",
  "1960",
  "1965",
  "1967",
  "1968",
  "1970",
  "1971",
  "1972",
  "1973",
  "1974",
  "1975",
  "1976",
  "1978",
  "1979",
  "1980",
  "1981",
  "1982",
  "1983",
  "1984",
  "1985",
  "1986",
  "1987",
  "1988",
  "1989",
  "1990",
  "1991",
  "1992",
  "1993",
  "1994",
  "1995",
  "1996",
  "1997",
  "1998",
  "1999",
  "2000",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
];

export const yearsList = [
  "1931",
  "1950",
  "1958",
  "1960",
  "1965",
  "1967",
  "1968",
  "1970",
  "1971",
  "1972",
  "1973",
  "1974",
  "1975",
  "1976",
  "1978",
  "1979",
  "1980",
  "1981",
  "1982",
  "1983",
  "1984",
  "1985",
  "1986",
  "1987",
  "1988",
  "1989",
  "1990",
  "1991",
  "1992",
  "1993",
  "1994",
  "1995",
  "1996",
  "1997",
  "1998",
  "1999",
  "2000",
  "2001",
  "2002",
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
];

export const intertestedPartyCode = [
  { label: "ACO", value: "1a61ab41-f3e9-065c-afed-62e9046ee83c" },
  { label: "ATT", value: "295df6a8-ea9f-a606-10c8-62e904aee620" },
  { label: "VFS", value: "3a507279-69cd-d1e7-67b2-62e904704ab5" },
  { label: "AEC", value: "4ae2e88c-2432-db46-89e2-62e9048c1877" },
  { label: "BOD", value: "705c64d4-71a8-9c41-a747-62e904124c7f" },
  { label: "BRO", value: "7991f741-2ed9-aea5-67bd-62e904378c0e" },
  { label: "FAU", value: "83808a11-09ea-e171-cedd-62e9045b85f3" },
  { label: "GTA", value: "8d36cde9-51ce-efae-b2ff-62e904c09779" },
  { label: "HER", value: "96b5695e-eb31-e9db-f409-62e904ae956c" },
  { label: "INN", value: "a09011bd-c587-50cc-4b08-62e904d0e57c" },
  { label: "PEL", value: "aa29aaae-7b44-dac0-a11c-62e904f858ce" },
  { label: "MAP", value: "b41a829f-aa22-3abe-da71-62e9041a631a" },
  { label: "RED", value: "be158033-9d1a-3544-9ef8-62e904675a40" },
  { label: "RCA", value: "c836bd01-0df0-8301-ca5b-62e904b3d0b8" },
  { label: "SMU", value: "d25b2a17-3fcc-4749-4c07-62e904253fa0" },
  { label: "BRA", value: "dcd73ff0-c449-7b84-9fa5-62e90426c42e" },
  { label: "NUC", value: "e7615a9f-537b-778f-8ffd-62e904010a49" },
  { label: "WE1", value: "f1ef26ff-953a-ef50-fd87-62e904c7f883" },
  { label: "WES", value: "8907052b-f6a9-1a9d-dd24-62e904432880" },
  { label: "WOO", value: "135dfb4f-77b3-f208-afa9-62e9047596d7" },
  { label: "YOR", value: "1e2a09af-aea4-8fe7-e0cc-62e9047edbe3" },
  { label: "CNE", value: "292eb9f0-c214-09e0-10d3-62e904ca8c69" },
  { label: "728", value: "342c128e-b476-c1d6-b322-62e90496cde6" },
  { label: "AON", value: "3f35c066-84e0-ad8c-4ad7-62e9041a83cd" },
  { label: "ASC", value: "4a5900a3-5154-e915-ec16-62e904ef315e" },
  { label: "BM", value: "55cf2df7-f21a-6123-9cdf-62e90432a65e" },
  { label: "BOM", value: "615ddd28-e566-a6ed-b6a2-62e9045ceb16" },
  { label: "BMO", value: "6ccb4eba-4071-8823-e1ee-62e9048e550a" },
  { label: "BM2", value: "78aa2091-7524-58ff-a8c4-62e9045e75cd" },
  { label: "BM3", value: "84576fdc-7501-0dde-346c-62e904cd91a8" },
  { label: "BM4", value: "90639b43-d1e0-dcad-dbef-62e904c81f79" },
  { label: "BEN", value: "9e9ebc99-dc0f-d8c4-206f-62e9046abe6b" },
  { label: "BCU", value: "aaf573ac-aa09-b3ab-4bb0-62e90401a8c4" },
  { label: "BDC", value: "b85bbf0c-c017-0cb3-56ed-62e90414a200" },
  { label: "BD1", value: "c518671f-24d0-ae42-df53-62e904deadc5" },
  { label: "BDB", value: "d1f3554b-7887-d550-e9e6-62e9043a1e75" },
  { label: "CT", value: "dee04942-7b7a-a4a0-afaf-62e9048b6abb" },
  { label: "CIB", value: "ebf9bf33-f4ea-1f7a-5c26-62e904a8920b" },
  { label: "FC", value: "54ce02d8-71cf-d80f-a8aa-62e904bebfd9" },
  { label: "CC", value: "13646af3-4614-6049-c6e9-62e9041bc2f8" },
  { label: "CHR", value: "214a87ce-2c88-a501-341a-62e9045d3ef8" },
  { label: "CITI", value: "2f4856e9-3dc0-c3ef-f703-62e904b6ec0c" },
  { label: "CMF", value: "3df48e03-1c1e-c2d9-8256-62e90447868f" },
  { label: "CCL", value: "4c91fae2-7c52-edbe-a1b7-62e904dd6210" },
  { label: "LES", value: "5b6616be-0320-8865-0408-62e904d44942" },
  { label: "DRE", value: "6a58297a-63a0-a364-f4b9-62e90414bcbb" },
  { label: "FIN", value: "796798c5-53c5-2fd3-f8d6-62e904e78929" },
  { label: "FDG", value: "887f250b-b4e0-1be6-f718-62e90425cff1" },
  { label: "FLM", value: "97b88393-087f-3d7f-db5b-62e904ebd9c5" },
  { label: "FNF", value: "a757710e-5511-13cd-e2ee-62e904a11ea1" },
  { label: "FIR", value: "b71002cb-4752-4eab-4ade-62e904d70b40" },
  { label: "FD", value: "c7488805-7424-7ec0-9a65-62e904ac915b" },
  { label: "FRD", value: "d7a2df6b-0f62-c094-6e99-62e90469c364" },
  { label: "FOR", value: "e8515aa8-4142-8497-5fe0-62e90401f052" },
  { label: "GA", value: "4e110f53-52c4-c43a-f425-62e904cf0c56" },
  { label: "GE", value: "162d631c-d780-2043-4819-62e9048bb56c" },
  { label: "GMA", value: "2753ae42-5283-32e9-b1a1-62e9045d06f9" },
  { label: "GMC", value: "39048697-8b68-73e9-f129-62e90481048e" },
  { label: "GM", value: "4a9b467e-9572-7e57-391e-62e904ec71f6" },
  { label: "GF", value: "5c47a70c-37d0-0cda-7e6f-62e904aeff8b" },
  { label: "QUE", value: "6cf46c12-4b3b-08a5-ab07-62e904210501" },
  { label: "HOM", value: "7ec0d768-4a51-35f7-a4fe-62e9044af685" },
  { label: "HCF", value: "90ff49a5-fc69-0264-d0b3-62e904d5b332" },
  { label: "HON", value: "a37e6fad-8ea6-62ca-12df-62e904a31e95" },
  { label: "HYU", value: "b683e4f5-f83f-906b-edc2-62e9049c65a0" },
  { label: "LA", value: "c90b417a-6304-7874-5c19-62e904fdd479" },
  { label: "ING", value: "dc6b2705-ae86-a5e5-7039-62e9047eb436" },
  { label: "IRW", value: "f0927d8b-7e4e-e0ee-6591-62e9049c8a2c" },
  { label: "LEA", value: "27ecca42-ca14-41d4-0848-62e904425f80" },
  { label: "LIT", value: "31b1d456-954e-ea3b-eca0-62e90440a94a" },
  { label: "MGD", value: "3b596b83-5c54-5706-3e21-62e904694465" },
  { label: "MAN", value: "4538c60c-c367-89c9-7171-62e904e49f82" },
  { label: "MTO", value: "4ef28b0d-efc7-d991-90d8-62e904490dd5" },
  { label: "MC", value: "58d74d2b-60f3-a651-6df3-62e904d635e3" },
  { label: "MZ", value: "62d10493-4c0d-eeac-a30e-62e904302902" },
  { label: "MCA", value: "6c2cae13-b247-f760-b66f-62e904436b67" },
  { label: "MER", value: "7671b272-5739-7bec-5137-62e9041903fe" },
  { label: "MT", value: "80b19c12-6337-826a-8a88-62e904406b5b" },
  { label: "MIL", value: "8afa5bef-25ef-3480-3ab5-62e904a7563a" },
  { label: "MVL", value: "956e2e10-fe41-2951-71d1-62e9042b2394" },
  { label: "NAT", value: "a0266f44-5de4-8061-7920-62e9048026b1" },
  { label: "NT", value: "aaf77896-1579-e8bf-b39d-62e904c4719d" },
  { label: "NC", value: "b672b64d-a3d4-f03c-a7b2-62e904a1e5d2" },
  { label: "N/A", value: "c1055f6d-97d4-687e-8c22-62e9046b6ada" },
  { label: "ORC", value: "cc40a783-3e6a-81a1-3e93-62e904308add" },
  { label: "ORF", value: "d7ef1475-35c0-d713-b06e-62e904116592" },
  { label: "PAC", value: "e35f7a35-7f3f-bccd-ab6d-62e9041f6fb0" },
  { label: "PET", value: "eea44155-13d1-6e3a-9ccd-62e9049e5e44" },
  { label: "POR", value: "63730d0e-9db9-f7a6-7bd0-62e9046ce13c" },
  { label: "PRI", value: "12c8193d-5c0b-7f14-a23f-62e9043176c5" },
  { label: "PRO", value: "1f2b19fc-bcd7-e10b-dc6e-62e90426066d" },
  { label: "REG", value: "2b6cc49b-6f8e-88db-5de8-62e90448a2c1" },
  { label: "RIO", value: "3757e2d6-7d8e-1c09-bdb7-62e9048bea59" },
  { label: "RB", value: "43625193-5d3d-8e63-81f4-62e904877c7e" },
  { label: "RT", value: "4f985c9f-5e3d-74b2-2d72-62e904499b86" },
  { label: "SBM", value: "5cf14ba8-725c-d51c-fd05-62e90427ba88" },
  { label: "NS", value: "6a5e8030-81da-81a1-b504-62e904789d67" },
  { label: "SMC", value: "77af0057-25b9-9c73-8e39-62e904820842" },
  { label: "SCO", value: "84dfd7ce-9d5a-1cc8-5888-62e9042d8e41" },
  { label: "1*", value: "942c1c4c-65ec-71f9-8b24-62e904558e03" },
  { label: "PIZ", value: "29665c78-dd19-abcb-0c47-62e904b0e3f2" },
  { label: "TDF", value: "38499309-0bd0-6a7e-893a-62e904112200" },
  { label: "TD", value: "452bcea9-4e99-ddbf-527b-62e904b351a5" },
  { label: "COR", value: "52617d4f-12d1-a1b6-65cc-62e904d41789" },
  { label: "EQU", value: "5f944881-0218-520a-051b-62e9042b8df8" },
  { label: "RB2", value: "6cf516bf-b8a7-1d36-2e79-62e9044911ee" },
  { label: "RB3", value: "7a2a41ea-924c-0b83-9c80-62e9043c0100" },
  {
    label: "TD1-4720 TAHOE BLVD",
    value: "883df914-1dd4-1fec-e2a9-62e9040d2a6f",
  },
  { label: "TOY", value: "9602fbff-1be3-8623-9d41-62e9049a4b6a" },
  { label: "VER", value: "a44e7712-71c3-fed5-4c6c-62e904580951" },
  { label: "VWC", value: "b2bad485-ab8e-f2ab-ed68-62e904572e1a" },
  { label: "VW1", value: "c12c815c-eb70-31fb-7977-62e904b6c2ed" },
  { label: "VW3", value: "d001ee27-4e6f-32cf-6f0a-62e904929a2e" },
  { label: "NEW", value: "dfd75af5-0ba5-2e01-1727-62e904933b8f" },
  { label: "MEREDIAN", value: "ef171d12-ead3-1695-4198-62e904d57c87" },
  { label: "SCOTIA-Calgary", value: "a35007d6-e018-109f-358c-62e9048cdde3" },
  { label: "NISSAN-ORBITOR DR", value: "195783b9-c8c6-be48-49b1-62e904e1774d" },
  { label: "NONE", value: "28076755-610a-5527-60d7-62e904510c76" },
  { label: "Wells", value: "38f8d623-5a7b-0f2d-1e33-62e904a9466e" },
  { label: "CIT", value: "488b47a9-cbde-4655-dbf8-62e90439a947" },
  { label: "TRA", value: "58b9ec94-143b-2076-cb4d-62e9043c22c9" },
  {
    label: "TD-Milverton Drive",
    value: "7972b97d-e20a-2123-58d8-62e90423034e",
  },
  { label: "Miscellaneous", value: "8962f313-4e5e-dff9-5426-62e9041ec5cc" },
];

export const TabSelectOptions = {
  "New (Upload)": ["Personal Auto", "Home", "Business", "Commercial Auto"],
  "Remarket (Upload)": ["Personal Auto", "Home", "Business", "Commercial Auto"],
  "Policy Change": [
    "General Change",
    "Vehicle Change",
    "Driver Change",
    "Coverage Change",
    "Garage Plate Change",
    "Interested Party Change",
    "Lessor Change",
    "Address Change",
    "Risk Location Change",
  ],
  "Payment Method": ["Payment Authorization Change", "Billing Change"],
  "Urgent Transactions": [
    "LAO/BOR",
    "Letter of Experience",
    "Cancellation/Reinstatement",
  ],
};

export const STANDARD_APP_DATE_FORMAT = "MM/DD/YYYY";
export const STANDARD_APP_TIME_FORMAT = "hh:mm A";
export const STANDARD_APP_DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm A";

export const add_marketing_history_quote = [
  "Submitted",
  "Recieved",
  "Accepted",
  "Declined",
  "Expired",
];

export const security_branch = [
  "Head Office(BR1)",
  "Head Office(BR1)",
  "Head Office(BR1)",
  "Head Office(BR1)",
  "Head Office(BR1)",
  "Head Office(BR1)",
  "Head Office(BR1)",
];

export const related_to = [
  "Customers",
  "Accountants",
  "Insurer",
  "Underwriter",
  "Broker",
  "Branch",
  "Branch Manager",
  "Producer",
  "Marketer",
  "CSR",
];

export const task_priority = ["Low", "Medium", "High", "Highest"];

export const task_status = [
  "Assigned",
  "Waiting",
  "Overdue",
  "Cancelled",
  "Ready to Release",
  "Voided",
  "Incomplete",
  "Completed",
];

export const create_opp_classification = [
  "Branch Manager",
  "Producer",
  "Business",
  "Partnership",
  "Other",
];

export const create_opp_source = [
  "Website",
  "Reference",
  "Email Marketing",
  "Social Media",
  "Campaign",
  "Cold Call",
  "Employee",
  "Trade Show",
  "Other",
];

export const structure_type = [
  "Apartment-Basement in Dwelling",
  "Apartment/Co-op",
  "Apartment Using Mercantile",
  "Apartment",
  "Detachd",
  "Semi-Detached",
  "Duplex",
  "Triplex",
  "Multi-plex (4 units or more)",
  "Folding Home",
  "High Rise",
  "Low Rise",
  "Modular Home",
  "Mobile Home",
  "Mini Home",
  "Pre-Cut Home",
  "Pre-fabricated unspecified",
  "Panelized Home",
  "Row House/Town House (Inside)",
  "Row House/Town House (End)",
  "Row House-Unspecified",
  "Town House-Unspecified",
  "Trailer(Stationary)",
];

export const foundation_type = [
  "Basement",
  "Brick",
  "Concrete Blocks",
  "Concrete/Masonary",
  "Crawlspace",
  "On Blocks",
  "On Timber",
  "On Wheels",
  "Post/Pier on Slab",
  "Poured Concrete",
  "Slab/Concrete Slab",
  "Stone",
];

export const exterior_wall_framing_type = [
  "Aluminum",
  "Aggregate",
  "Asbestos",
  "Brick",
  "Brick Veneer",
  "Cement",
  "Concrete Block / Masonary Frame",
  "Fibreglass",
  "Fire Resistive",
  "Frame (Wood)",
  "Log",
  "Masonite",
  "Masonary",
  "Masonary Veneer",
  "Monocoque",
  "Non-fire Resistive Apartment",
  "Steel",
  "Stone",
  "Stone Veneer",
  "Stucco",
  "Vinyl",
  "Wood Exterior",
];

export const interior_wall_construction_type = [
  "Acoustic Tile",
  "Carpet",
  "ceramic/Porcelain ",
  "Drywall",
  "Hardwood",
  "Laminate",
  "Plaster",
  "Vinyl",
  "Wood Panelling",
];

export const roof_covering_type = [
  "Aluminum",
  "Asphalt",
  "Asphalt Shingles",
  "Clay Tile",
  "Concrete Tile",
  "Corrugated Sterl",
  "Metal Tile",
  "Mineral Fibre Shakes",
  "Oter Tile",
  "Plastic",
  "Rubber",
  "Slay Tile",
  "Tar and Gravel",
  "Wood Shakes",
  "Wood Shingles",
];

export const electrical_wiring_type = [
  "Aluminum",
  "Copper",
  "Knob/Tube",
  "Mixed",
];

export const electrical_panel_type = ["Breakers", "Fuses"];

export const apparatus = [
  "Baseboard Heaters",
  "Bi-energy/Combination",
  "Electric Furnace (Pipeless Warm Air Furnace/Recessed)",
  "Fireplace",
  "Fireplace Insert",
  "Floor Furnace",
  "Furnace (Central)",
  "Furnace (Central) With Add on Burning Unit",
  "Geo Thermal / Heat Pump",
  "Hot Water / Steam / Boiler Unit",
  "None",
  "Radiant Ceiling",
  "Radiant Floor",
  "Solid Fuel Heating Unit",
  "Space Heater",
  "Wall Furnace",
];

export const fuel = [
  "Butane",
  "Coal",
  "Combination",
  "Electric",
  "Electric/Oil",
  "Electric/Wood",
  "Ethanol/Alchohol",
  "Grain",
  "kerosene",
  "Liquified Petroleum Gas",
  "Natural Gas",
  "Naptha Gas",
  "Oil",
  "Oil/Wood",
  "Other Solid Fuel",
  "Pellets",
  "Propane",
  "Solar",
  "Wood",
  "Wind",
];

export const hibatational_location = [
  "Attic",
  "Barn",
  "Basement",
  "Crawalspace",
  "Garage",
  "Kitchen",
  "Main Floor",
  "Outside",
  "Rooftop",
  "Second Floor",
  "Unknown",
];

export const water_heater_apparatus = [
  "Combination Space-Water",
  "Heat Pump",
  "Solar",
  "Storage Tank",
  "Tankless (On Demand)",
];

export const water_pump_type = [
  "Floor Sucker (Electric)",
  "Pedestal (Electric)",
  "Submersible (Electric)",
  "Water Powered",
  "None",
];

export const water_auxiliary_power = ["Battery", "Generator", "None"];

export const water_back_up_value = ["Flapper", "Gate", "None"];

export const main_value_shout_off = [
  "Automatic",
  "Manual",
  "Monitored",
  "Unknown",
];

export const distance_to_hydrant = [
  "Unprotected",
  "Within 150m",
  "Within 300m",
  "Over 300m",
];

export const hydrant_type = [
  "Standard",
  "Non-standard , Standpipe",
  "Tanker",
  "Superior Shuttle Tanker Service",
  "Lake, Pond, etc",
];

export const distance_to_reponding_fire_hall = [
  "Not Specified",
  "Within 5 kilometers",
  "Within 8 kilometers",
  "Over 8 kilometers",
  "Within 13 kilometers",
  "Over 13 kilometers",
  "1 kilometers",
  "2 kilometers",
  "3 kilometers",
  "4 kilometers",
  "5 kilometers",
  "6 kilometers",
  "7 kilometers",
  "8 kilometers",
  "9 kilometers",
  "10 kilometers",
  "11 kilometers",
  "12 kilometers",
  "13 kilometers",
  "14 kilometers",
  "15 kilometers",
  "16 kilometers",
  "17 kilometers",
  "18 kilometers",
  "19 kilometers",
  "20 kilometers",
  "21 kilometers",
  "22 kilometers",
  "23 kilometers",
  "24 kilometers",
  "25 kilometers",
  "26 kilometers and over",
];

export const fire_burglar_smoke = [
  "Local Complete",
  "Local Partial",
  "Monitored Complete",
  "Monitored Partial",
  "None",
];

export const smoke_detector_type = [
  "Ionization",
  "Photoelectric",
  "Ionization and Photoelectric",
  "unknown",
];

export const security_type = [
  "Intercom",
  "24 hour Security",
  "Gated Community Service",
  "Part Time Security & Intercom",
  "Secured Intrance",
  "Security Attendant",
  "None",
];

export const kitchen_security = [
  "Builder's Grade",
  "Customer",
  "Economy",
  "Luxury",
  "Standard",
];

export const garage_type = [
  "Attached - Frame",
  "Attached - Masonary",
  "Attached - Masonary or Masonary/Veneer",
  "Basement",
  "Basement Under Attached Garage",
  "Built-in",
  "Carport",
  "Carport with Storage",
  "Detached - Frame",
  "Detached - Masonary or Masonary/Veneer",
  "Detached Garage Masonary",
  "None",
];

export const pool_type = [
  "Indoor, Above Ground",
  "Indoor, In Ground",
  "Indoor, Semi In Ground",
  "Outdoor, Above Ground",
  "Outdoor, In Ground",
  "Outdoor, Semi In Ground",
];

export const mortgagee_nature_of_interest = [
  "Mortgagee (Financial Institution)",
  "Mortgagee (Other)",
  "Accounting Contact",
  "Additional Interest",
  "Attorney in Fact",
  "Additional Insured",
  "Additional Lessor",
  "Actual Owner",
  "Certificate Holder",
  "Canadian",
  "Corporate Owner",
  "Escrow Corporation",
  "Executor",
  "Finance Company",
  "Inspection Contact",
  "Line of Credit",
  "Lienholder",
  "Loss Payee",
  "Lessor",
  "Mortgage Servicing Agency",
  "Municipality",
  "Both Loss Payee and Additional Insured",
  "Registered Owner",
  "Secured Loss Payee",
];

export const city_province_state = [
  "AB",
  "BC",
  "MB",
  "NB",
  "NL",
  "NS",
  "ON",
  "PE",
  "QC",
  "SK",
  "NT",
  "NU",
  "YT",
];

export const habitational_attachment = [
  "Appraisal",
  "Association/Membership",
  "Banking Authorization",
  "Bill of Sale",
  "Billing Notice",
  "Building Evaluator",
  "Certificate",
  "Claim Investigation Report",
  "Declaration for Retiree Discount Form",
  "Driver Training Certificate",
  "Driver's License",
  "Electronic Data Memorandum",
  "Evaluation Request Form",
  "Expiring Policy",
  "Financial Statement",
  "Inspection Report",
  "Letter of Authorization",
  "Letter of Experience",
  "Loss History",
  "Medical Report",
  "Motor Vehicle Report",
  "Non-smoker Form",
  "Ownership",
  "Payment Reminder Notice",
  "Photograph",
  "Policy Declaration",
  "Prior Policy History",
  "Proof of Purchase",
  "Questionnaire",
  "Record of Student Grades",
  "Release of Interest",
  "Replacement of Cost Worksheet(s)",
  "Scheule",
  "Signed Electronic Funds Transfer Form",
  "Void Checque",
];

export const breed_dogs = [
  "Akita",
  "Chow Chow",
  "Doberman Pinscher",
  "German Shepherd",
  "Mix - Doberman and Shepherd, etc",
  "Pit Bull",
  "Rotweiller",
  "Wolf Hybrid",
];

export const describe_business = [
  "Arcade",
  "Art Gallery",
  "Automobile (Repair)",
  "Automobile (Sales , Accessories)",
  "Accounting Service",
  "Actuary",
  "Adjuster",
  "Administrative Support Service",
  "Advertising/Marketing Service",
  "Antique Gallery Shop",
  "Appraiser",
  "Aquarium Sales and Service",
  "Arbitration Service",
  "Architect",
  "Artists Goods and Material Sales",
  "Audiologist",
  "Auditing",
  "Bakery, Pastry Shop",
  "Barber Shop and Hairdresser",
  "Book Shop and Stationary Shop",
  "Bronzing Salon",
  "Baked Goods",
  "Beauty Salon / Barber Shop",
  "Bed and Breakfast",
  "Bike Repair",
  "Book Binding",
  "Book Publishing",
  "Book/Magazine Distribution",
  "Bookkeeping Service",
  "Book/Magazines Sales",
  "Bridal Consultant",
  "Cleaner",
  "Calligrapher",
  "Camera Repair",
  "Candy/Nut Confections",
  "Catalagoue and Mail Orders",
  "Catering Service",
  "Ceramics and Pottery (No Kiln on Premises)",
  "Ceramics and Pottery (With Kiln)",
  "Chiropractor's Office",
  "Clock Repair",
  "Clothing and Accessories Sales",
  "Clown, Magician and Entertainer",
  "Computer Consulting/Programming",
  "Computer Lessons",
  "Computer Repair",
  "Copywriting",
  "Cosmetic and Personal Care Sales",
  "Costume Rental",
  "Craft Sales",
  "Cullinary Tutoring",
  "Curtain/Drapes, Manufacturing and Sales",
  "Drugstore",
  "Day Care Center",
  "Dress Maker (Sewing)",
  "Dance School Instructor",
  "Data Entry Clerk",
  "Day Care Service",
  "Dentist",
  "Dermatologist's Office",
  "Desktop Publishing",
  "Dietician (Consultant)",
  "Direct Advertising",
  "Doctor",
  "Dog Kennel",
  "Doll Making",
  "Drafting",
  "Dramatic Art & Speech Training",
  "Electrical Appliance (Retail)",
  "Editor/Proofreader",
  "Educational Councellor/Tutor",
  "Electronic Equipment Repair",
  "Employement Agency",
  "Engineer",
  "Engraver",
  "Esoteric Service",
  "Estate Planning",
  "Financial Institution",
  "Funeral Home",
  "Florist",
  "Fabric Painting, Inking, Dyeing",
  "Financial Planning/Consultant",
  "Firewood Sales & Delivery",
  "Fishing Tackle",
  "Flower Arranging",
  "Flower Sales",
  "Food Broker",
  "Food Supplements/Vitamins",
  "Foster Home",
  "Grocery Store",
  "Geneologist",
  "Gift Delivery Service (Balloons, Gun Baskets)",
  "Giftware Sales",
  "Glassware",
  "Golf Club Making & Repair",
  "Graphic Arts Designer",
  "Greenhouse",
  "Greeting Cards",
  "Group Home",
  "Handy Store",
  "Hardware Store",
  "Handyman",
  "Hearing Aid Outlets Sales and Repairs",
  "Hobby & Model Supplies & Accessories",
  "Hobby/Models and Accessories",
  "Home Care for the Elderly",
  "Home Party Sales (Household Goods, Clothing, Food Services)",
  "Household Products and Appliances",
  "Human Resource Consultant",
  "Insurance Agent",
  "Image Consultant",
  "Income Tax Consulting",
  "Insurance Agent/Broker/Consultant",
  "Insurance Valuation Inspection Services",
  "Insurance Valuation Inspection Services",
  "Interior Designer",
  "Jams/Preserves Sales",
  "Jewellery",
  "Kitchen Supplies",
  "Land Title Search",
  "Landscaping ",
  "Lawyer's Office",
  "Leather Craft",
  "Leather Goods Sales",
  "Lingerie Sales",
  "Locksmith",
  "Mail Receiving",
  "Mailing List Services",
  "Management Consultant",
  "Market Research and Analysis",
  "Massage Therapist",
  "Modelling Agency & School",
  "Mortgage Broker",
  "Music Lessons/Studio",
  "Musical Instruments Sales and Repairs",
  "Ninepins and Pool Room",
  "Needle Work (Embroidery, Knitting, Macrame, Crewel Work)",
  "Notary Public",
  "Office",
  "Office Designing & Planning",
  "Office Employee/Contract Worker",
  "Office Services (Including Faxing, Photocopying, Quick Printing)",
  "Office Supplies Sales",
  "Optician Sales and Repairs",
  "Optometrist",
  "Photography Studio",
  "Public House (Alcohol)",
  "Paper Mache, Paper Tole",
  "Paralegal Agent",
  "Party Planning/Rental Service",
  "Pet Grooming Service",
  "Pet/House Sitting",
  "Photographer, Photography Studio",
  "Piano, Tuning & Repairing",
  "Picture Framing",
  "Picture Painting",
  "Plants - Indoor Maintenance",
  "Podiatrist",
  "Psychologist",
  "Public Relations Service",
  "Public Speaking Service",
  "Repair Shop (Electrical Appliance)",
  "Restaurant",
  "Retail Store",
  "Retail Store (Electrical)",
  "Retail Store (Garments)",
  "Retail Store (Paint)",
  "Retail Store (Sport)",
  "Roomer",
  "Real Estate Sales",
  "Religious Goods",
  "Relocation Service",
  "Residential Inspection Service",
  "Resume Service",
  "Service Station",
  "Sales Representative",
  "Secretarial/Stenographic Service",
  "Sewing/Tailoring/Altering Service",
  "Sharpening Service",
  "Shoe Repair",
  "Sign Designer",
  "Small Appliance Repair",
  "Speech/Language Pathologist",
  "Stained Glass Craft",
  "Stencilling of Pictures",
  "Sun Tanning Bed",
  "Surveyors-land",
  "Tobacconist's Shop",
  "Taxidermist",
  "Telephone Answering Service",
  "Telephone Sales/Telemarketing",
  "Toy Sales",
  "Transcribing Service, Court Reporter",
  "Translation Service",
  "Travel Agent/Travel Planner",
  "Trophy Sales",
  "Tupperware Sales",
  "Upholstery",
  "Video Taping Service Including Editing",
  "Vocational Counsellor",
  "Window Display Decorator",
  "Woodworker",
  "Writer",
  "Other Commercial Occupation",
];

export const STAR_EMAIL = "STAR_EMAIL";
export const IMPORTANT_EMAIL = "IMPORTANT_EMAIL";
export const OPEN_EMAIL = "OPEN_EMAIL";
export const TRASH_EMAIL = "TRASH_EMAIL";
export const ASSIGN_FOLDER = "ASSIGN_FOLDER";
export const ASSIGN_LABEL = "ASSIGN_LABEL";
export const SET_SELECTED_EMAIL = "SET_SELECTED_EMAIL";
export const FILTER_EMAIL = "FILTER_EMAIL";
export const SET_EMAIL_VISIBILITY_FILTER = "SET_EMAIL_VISIBILITY_FILTER";

export const LEAD_STATUS = [
  { label: "Active", value: "ACTIVE" },
  { label: "New", value: "NEW" },
  { label: "In-Process", value: "INPROGRESS" },
  { label: "Converted", value: "CONVERTED" },
  { label: "Spam", value: "SPAM" },
  { label: "Duplicate", value: "DUPLICATE" },
  { label: "Dead", value: "DEAD" },
  { label: "Recycle", value: "RECYCLE" },
  // { label: "Convert to Opportunity", value: "CONVERT TO OPPORTUNITY" },
];

export const OOP_STATUS = [
  { label: "In Process", value: "ACTIVE" },
  { label: "Prospecting", value: "Prospecting" },
  { label: "Qualifications", value: "Qualifications" },
  { label: "Needs Analysis", value: "Needs Analysis" },
  { label: "Value Proposition", value: "Value Proposition" },
  {
    label: "Identifying Decision Makers",
    value: "Identifying Decision Makers",
  },
  { label: "Perception Analysis", value: "Perception Analysis" },
  { label: "Proposal", value: "Proposal" },
  { label: "Negotiation", value: "Negotiation" },
  { label: "Closed Won", value: "Closed Won" },
  { label: "Closed Lost", value: "Closed Lost" },
  { label: "Convert to Opportunity", value: "CONVERT TO OPPORTUNITY" },
];

export const CSIO_COMPANY_STATUS = [
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "INACTIVE" },
];

export const COMMON_STATUS = [
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "INACTIVE" },
];

export const liability_extension_exclusion = [
  "Animal Exclusion",
  "Day Care Activities Exclusion",
  "Foster Home Exclusion",
  "Vandalism by Tenants Exclusion",
  "Additional Named Insured",
  "Additional Residences/Properties/Acreage",
  "Animal Collision",
  "Animal Liability Extension",
  "Bed & Breakfast",
  "Day Care Extension",
  "Incidental Business Pursuits Endorsement",
  "Condominium Contingent Legal Liability",
  "Public Employee Liability For Building Under Construction",
  "Construction Liability",
  "Elderly Care Home Extension",
  "Exhibition of Equipment",
  "Elevator",
  "Exhibition of Animals",
  "Farm Machinery",
  "Foster Care Home Extension",
  "Forest and Prairie Protection Act",
  "Group Home Extension",
  "Golf Cart",
  "Horse",
  "Increased Legal Liability, Homeowners, Tenants & Condominium Forms",
  "Limited Liability Extension",
  "Legal Liability Loss Assessment",
  "Legal Liability Loss Assessment Bare Land",
  "Office, Professional, Private School or Studio Use (Other Premises)",
  "Office, Professional, Private School or Studio Use (Res. Premises)",
  "Permission to Use Auxiliary Heating",
  "Permission to Use Auxiliary Heating in Case of Electricity Failure",
  "Personal Injury Endorsement",
  "Premises Coverage Limitation",
  "Premises Extension",
  "Professional Activity",
  "Residence Employees Liability",
  "Residence Premises - 2 to 6 Family Dwelling",
  "Rental - Seasonal Dwelling",
  "Rental Watercraft (Rented to Others)",
  "Residence Voluntary Compensation - Chauffeur",
  "Residence Voluntary Compensation - Resident Employee",
  "Residence Voluntary Comp. (Obsolete: see RVCCH,RVCIN,RVCOC,RVCOU)",
  "Residence Voluntary Compensation - Occasional",
  "Residence Voluntary Compensation - Non-resident Employee",
  "Solar Panel",
  "Swimming Pool or Pond",
  "Sugar Shack With Reception Hall",
  "Temporary Additional Location Endorsement",
  "One to Six Family Dwelling Premises Liability",
  "Watercraft Liability",
  "Waterbed",
  "All Terrain Vehicles - Basic",
  "All Terrain Vehicles - Passenger Hazard",
  "All Terrain Vehicles - Underage Operator",
  "Building under construction",
  "Waterjet Propulsion Personal Watercraft - Basic",
  "Waterjet Propulsion Personal Watercraft - Passenger Hazard",
  "Waterjet Propulsion Personal Watercraft - Underage Operator",
  "Private Lake",
  "Roomer",
  "Seasonal Residence",
  "Supplementary Lodging",
  "Vacant Land",
];

export const deductible_type = [
  "Flat",
  "Percent",
  "Disappearing Flat",
  "Disappearing Perce",
];

export const discount_surcharge = [
  "Discount- Association/Membership",
  "Discount-Broker Multi Line",
  "Discount- Broker Self Service",
  "Discount- Business Use",
  "Discount-Claims Forgiveness",
  "Discount- Combined Age-Mortgage-Free",
  "Discount-Cooperative (Co-op)",
  "Discount- Diesel Engine",
  "Discount-Employee",
  "Discount-Evaluator Based",
  "Discount-Female",
  "Discount-Group Rate Applied",
  "Discount-Hail Resistant",
  "Discount-Heating",
  "Discount-Home Security",
  "Discount-Long Term Occupancy",
  "Discount- Mature Citizen",
  "Discount-Mature Homeowner - In Premises 'n' Years",
  "Discount- Maximum Security",
  "Discount- Miscellaneous",
  "Discount- Mobile Home Tied Down",
  "Discount- Mortgage Free",
  "Discount- Multiple Family",
  "Discount- Multiple Line",
  "Discount- Multiple Property Risks",
  "Discount- Multiple Units",
  "Discount-Neighbourhood Watch/Crime Prevention Programme",
  "Discount-New Business",
  "Discount- New Home",
  "Discount-New Hot Water Tank",
  "Discount-New Policy",
  "Discount-No Claims",
  "Discount-Non Smoker",
  "Discount-Occupation",
  "Discount-Owner Occupied",
  "Discount-Preferred Construction",
  "Discount-Premises Alarm (Fire or Intrusion)System",
  "Discount- Professional",
  "Discount- Protection Device",
  "Discount-Quality Home",
  "Discount- Renewal",
  "Discount-Retiree",
  "Discount-Risk Premium Cap",
  "Discount-Safety Equipment",
  "Discount-Self Bailing",
  "Discount-Senior Citizen (Age Related)",
  "Discount-Septic System",
  "Discount- Smoke Detector",
  "Discount-Sprinklered",
  "Discount-Stability",
  "Discount-Tanker Shuttle Service (Fire Fighting Service)",
  "Discount-Tankless Water Heater",
  "Discount-Tenants 24-Hour Surveillance",
  "Discount-Total Discount Applied",
  "Discount- Unfinished Basement",
  "Discount-Valued/Preferred Customer",
  "Discount-Water Sensor",
  "Discount Water (Maximum Conditions)",
  "Discount-Watercraft Operator Certificate",
  "Discount-Year Built (Other than New Home)",
  "Discount-Years of Experience",
  "Surcharge- Absentee Landlord (Rented Dwelling)",
  "Surcharge-Age of Structure",
  "Surcharge- Age/Condition of Home",
  "Surcharge- Below Grade",
  "Surcharge- Business Use",
  "Surcharge- Claims",
  "Surcharge- Commercial Exposure",
  "Surcharge-Commercial Exposure High",
  "Surcharge- Commercial Exposure Low",
  "Surcharge- Commercial Exposure Moderate",
  "Surcharge- Coverage Ineligible",
  "Surcharge- Criminal Record",
  "Surcharge-Hail Damage Susceptibility",
  "Surcharge- High Value",
  "Surcharge- Inconsistent Insurance History",
  "Surcharge-Coverage Ineligible",
  "Surcharge- Criminal Record",
  "Surcharge- Hail Damage Susceptibility",
  "Surcharge- High Value",
  "Surcharge- Inconsistent Insurance History",
  "Surcharge- Log Home",
  "Surcharge- Miscellaneous",
  "Surcharge- Mono-line Policy",
  "Surcharge- Multiple Family",
  "Surcharge- Multiple Units",
  "Surcharge- No Previous Insurance",
  "Surcharge- Non-payment",
  "Surcharge- Occupancy",
  "Surcharge- Personal Watercraft",
  "Surcharge- Primary Heating",
  "Surcharge- Prior Policy Cancellation",
  "Surcharge- Risk Premium Cap",
  "Surcharge-Seasonal Occupancy",
  "Surcharge- Secondary/Auxiliary Heating",
];

export const coverage_description = [
  "Accidental Death and Dismemberment",
  "Actual Cash Value Endorsement",
  "Actual Cash Value for Sewer Back-up",
  "Additional Amount of Personal Property Away From Premises",
  "Additional Living Expense (Homeowners, Tenant and Condominium Forms)",
  "Additional Rebuilding Cost",
  "Alarm Warranty",
  "All Risk - Loss Assessment Coverage",
  "All Risk - Personal Property",
  "All Risk - Solar Panel",
  "All Risk - Unit Owners Additional Protection",
  "All Risk - Unit Owners Building Improvements and Betterments Increased Limits",
  "Backwater Valve Maintenance Clause",
  "Builder's Risk Insurance",
  "Building - Other than Residence for Homeowner",
  "Business Property",
  "Bylaws Endorsement",
  "Care Facility Contents Extension",
  "Claim Free Protection",
  "Commercial Stock",
  "Condominium Bare Land Loss Assessment (Property Section)",
  "Contamination",
  "Credit Card Forgery and Counterfeit Money Coverage",
  "Damage Caused by Wild Life",
  "Damage Due to Illicit Narcotics Exclusion",
  "Damage to Outdoor Swimming Pool & Attached Equipment",
  "Day Care",
  "Debris Removal",
  "Declaration of Emergency Endorsement - Extension of Termination or Expiry Date",
  "Deductible Assessment Buydown",
  "Deferred Loss Settlement Clause",
  "Demolition Increased Cost of Construction",
  "Dependents Coverage Endorsement",
  "Detached Private Structures Exclusion Endorsement",
  "Detached Private Structures Limitation Endorsement",
  "Dwelling - Residence for Homeowner",
  "Earthquake",
  "Emergency Medical Travel Insurance",
  "Endorsement for change in deductible notice",
  "Enhanced coverage option for selected clients",
  "Enhanced Internal Limits",
  "Extended Coverage Deletion",
  "Extended Theft Away from Premises",
  "Farmers Comprehensive Personal Liability",
  "Fire Department Service Charge",
  "Flood Damage (Surface Water)",
  "Furnished Rented Property",
  "Glass Breakage",
  "Glass Deductible Endorsement",
  "Golf Package Endorsement",
  "Guaranteed Replacement Cost - Building",
  "Hail Coverage Endorsement",
  "Hobby Farm",
  "Home Accessibility Extension",
  "Home Business Coverage",
  "Home Business Off-Premise",
  "Home Business On-Premise",
  "Home Freezer",
  "Identity Theft",
  "Inflation Guard Endorsement",
  "Leakage or Overflow of Domestic Fuel Oil",
  "Legal Assistance",
  "Legal Fees",
  "Legal Liability Other than Homeowners, Tenant and Condominium Forms",
  "Legal Liability - Homeowners, Tenant and Condominium",
  "Livestock - Broad Named Perils",
  "Livestock - Limited Named Perils",
  "Locked Vehicle Warranty",
  "Log Construction Settlement Limitation",
  "Malicious Damage - Building & Contents",
  "Malicious Damage on Building",
  "Malicious Damage on Contents",
  "Mass Evacuation Endorsement",
  "Mould Exclusion",
  "Named Perils - Loss Assessment Coverage",
  "Named Perils - Solar Panel",
  "Named Perils - Unit Owners Additional Protection",
  "Named Perils - Unit Owners Building Improvements and Betterments Increased Limits",
  "Other (Appurtenant) Structures (Residence Premises)",
  "Other Structures - Homeowners Forms",
  "Other Structures Rented to Others (Residence Premises)",
  "Out of Province Medical",
  "Personal Effects Floater - Domiciled Endorsement",
  "Personal Liability (Umbrella Endorsement)",
  "Personal Liability (Umbrella Standalone)",
  "Personal Property - Homeowners Form",
  "Personal Property - Other than Homeowners, Tenant and Condominium Forms",
  "Personal Property - Tenant and Condominium Unit Owners Form",
  "Post-earthquake Damage",
  "Power Fluctuation",
  "Property of Students Coverage",
  "Renewable Energy Income",
  "Rental Income",
  "Rented Unit Owners Endorsement",
  "Replacement Cost - Building",
  "Replacement Cost - Contents/Basic (must be used as separate coverage code with separate premium)",
  "Replacement Cost - Contents/Extended (must be used as separate coverage code with separate premium)",
  "Replacement Cost Package",
  "Residence Burglary",
  "Restricted Water Damage",
  "Roof (Limited to Cash Value)",
  "Roof (Replacement Cost Value)",
  "Roof (Settlement Basis) due to Windstorm/Hail",
  "Self-contained Apartment",
  "Service Line Extension Endorsement",
  "Sewer Back-up Coverage",
  "Sewer Back-up Limitation",
  "Single Limit",
  "Special Loss Settlement",
  "Sprinkler Bylaw",
  "Theft and Burglary",
  "Theft Exclusion",
  "Unit Owners Other Structures",
  "Unlicensed Snow Vehicle, Trail Bike or ATV",
  "Vacancy Permit",
  "Voluntary Medical Payments",
  "Voluntary Property Damage",
  "Waived Premium",
  "Water Damage Deductible",
  "Water Damage Endorsement",
  "Water Damage Exclusion",
  "Waterbed",
  "Weight of Snow and Ice",
  "Wind Coverage Endorsement",
  "Wind/Hail Deductible Endorsement",
  "Wind/Hail Exclusion",
  "Windstorm/Hail",
  "Wood Stove Limitation",
  "Wood Stove Warranty",
  "Wreckage Value",
  "Special Limit- Bicycle",
  "Special Limit-Books, Tools, and Instruments Pertaining to a Business",
  "Special Limit- Coin Collection",
  "Special Limit-Compact Discs or Audio Tapes",
  "Special Limit-Furs",
  "Special Limit-Golf Carts",
  "Special Limit- Increased Limit for Blanket Coverage",
  "Special Limit- Jewellery/Watches/Gems",
  "Special Limit- Money and Bullion",
  "Special Limit- Personal Computer (Software)",
  "Special Limit- Securities",
  "Special Limit- Silverware",
  "Special Limit-Sports Card Collection",
  "Special Limit-Stamps",
  "Special Limit-Theft from Vehicle",
  "Special Limit- Tombstones (Grave Markers)",
  "Special Limit- Watercraft",
  "Special Limit-Windstorm",
  "Special Limit- Windstorm",
  "Miscellaneous Vehicles- All Risks",
  "Miscellaneous Vehicles- Named Perils",
  "Snowmobiles- All Risks",
  "Snowmobiles- Named Perils",
  "Travel Trailers- All Risks",
  "Travel Trailers-Broad Form",
  "Travel Trailers-Named Perils",
  "Travel Trailers-Contents - All Risks",
  "Travel Trailers-Contents - Named Perils",
  "Travel Trailers- Emergency Living Expenses",
  "Travel Trailers-Emergency Road Service",
  "Travel Trailers-Temporary Attachments - All Risks",
  "Travel Trailers-Temporary Attachments - Named Perils",
  "Watercraft- Additional Miscellaneous Equipment - All Risks",
  "Watercraft- Additional Miscellaneous Equipment - Named Perils",
  "Watercraft-Hull - All Risk",
  "Watercraft-Hull - Named Perils",
  "Watercraft-Hull - Named Perils and Marine Perils",
  "Snowmobiles- All Risks",
  "Snowmobiles- Named Perils",
  "Travel Trailers- All Risks",
  "Travel Trailers-Broad Form",
  "Travel Trailers-Named Perils",
  "Travel Trailers-Contents - All Risks",
  "Travel Trailers-Contents - Named Perils",
  "Travel Trailers-Emergency Living Expenses",
  "Travel Trailers-Emergency Road Service",
  "Travel Trailers-Temporary Attachments - All Risks",
  "Travel Trailers-Temporary Attachments - Named Perils",
  "Watercraft-Additional Miscellaneous Equipment - All Risks",
  "Watercraft-Additional Miscellaneous Equipment - Named Perils",
  "Watercraft-Hull - All Risk",
  "Watercraft-Hull - Named Perils",
  "Watercraft-Hull - Named Perils and Marine Perils",
  "Watercraft- Motor - All Risks",
  "Watercraft-Motor - Named Perils",
  "Watercraft-Motor - Named Perils and Marine Perils",
];

export const type_of_payment = [
  "Monthly",
  "Annual",
  "2 Payments(Equal) - 50% per payment",
  "3 Pay (Equal) - 33.3% per payment",
  "3 Payments (40% 1st Payment, 30% for the next 2 Payments)",
  "Payments (50% 1st Payment, 25% for the next 2 Payments)",
  "3 Year (Annual Installments)",
  "3 Year (Lump Sum Payments)",
  "4 Payments (Equal) - 25% per payment",
  "Bi-monthly (every 2 months)",
  "Bi-weekly",
  "Down Payment (indicated in PAY04) -10 Monthly Payments",
  "Non-standard (See Data Elements PAYO3 - PAYO8)",
  "Quarterly",
  "Semi-annual",
  "Six-month Policy Four Equal Payments",
  "Six-month Policy Single Payment",
  "Six-Month Policy Six Equal Payments",
  "Six-month Policy Three Equal Payment",
  "Six-month Policy Two Equal Payments Weekly",
];

export const applicant_occupation = [
  "Accountant",
  "Actuary",
  "Administrator/Director",
  "Agronomist",
  "Airline Personnel",
  "Architect",
  "Armed Forces",
  "Artist (Without Other Profession)",
  "Athlete - Professional",
  "Automobile Mechanic",
  "Automobile Sales/Dealer",
  "Bank/Financial Institution - Administration",
  "Bank/Financial Institution - Clerical",
  "Bank/Financial Institution - Management",
  "Barber, Hairdresser",
  "Carpenter",
  "Cashier",
  "Casino Worker",
  "Chartered Accountant",
  "Civil Service",
  "Clergy",
  "Coach",
  "Computer - Analyst, Consultant, Programmer, etc.",
  "Consultant - _____",
  "Consultant - Human Resources",
  "Consultant - Training",
  "Courts e.g. Bailiff, Clerk, Reporter, Stenographer, etc.",
  "Craftsman",
  "Crown Corporation Employee",
  "Daycare Worker, Provider",
  "Dental Hygienist",
  "Dentist, Orthodontist, Denturologist",
  "Dietician, Nutritionist",
  "Doctor - General Practitioner",
  "Driver - Local Delivery",
  "Driver - Long Haul, Mover",
  "Driver - Public Transportation",
  "Driver - Taxi, Chauffeur, Instructor",
  "Electrician",
  "Engineer - Professional",
  "Entertainment Field e.g. Actor, Musician, etc.",
  "Executive",
  "Factory Worker, Machine Operator, Tool & Die Worker",
  "Farmer",
  "Financial Advisor, Broker, Economist",
  "Firefighter",
  "Fisherman",
  "Fitness, Health Employee",
  "Flight Attendant",
  "Food Industry - Other e.g. Butcher, Slaughterer",
  "Forest Employee",
  "General Contractor",
  "Graphic Designer, Printer",
  "Homemaker (not Spouse at Home), Caregiver",
  "Horticulture",
  "Hotel, Motel Worker",
  "Insurance - Adjuster",
  "Insurance - Agent, Broker",
  "Insurance - Underwriter",
  "Labourer - Unskilled",
  "Lawyer",
  "Librarian",
  "Maintenance, Janitor, Cleaner",
  "Manager",
  "Marketing Employee",
  "Medical Specialist e.g. Anaesthetist, Chiropractor, Podiatrist, etc.",
  "Miner",
  "Notary",
  "Nurse",
  "Office Worker",
  "Optician",
  "Optometrist, Ophthalmologist",
  "Painter, Plasterer",
  "Pharmacist",
  "Photographer, Photography Studio",
  "Pilot",
  "Police Officer",
  "Postal Worker",
  "Property Manager",
  "Psychologist",
  "Public Transportation Worker - Other Than Airline",
  "Real Estate Agent",
  "Restaurant, Bar - Owner, Employee e.g. Waiter, Bartender, etc.",
  "Restaurant, Bar - Chef, Cook, Caterer",
  "Retail Sales Person",
  "Retired",
  "Salesperson - Commercial",
  "Scientist, Researcher e.g. Biochemist, Biologist, Chemist, etc.",
  "Seamstress",
  "Security Officer, Worker",
  "Self-Employed - ____",
  "Self-Employed - Services e.g. Chimney Sweep, Lawn Care, Window Cleaner",
  "Spouse at Home",
  "Student",
  "Supervisor, Foreman",
  "Surveyor",
  "Teacher, Principal, Professor, Lecturer",
  "Technicians - Research, Medical",
  "Therapist",
  "Tradesman",
  "Unemployed",
  "Urbanist (Professional Urban Planner)",
  "Veterinarian",
  "Writer, Publisher",
];

export const cause_of_loss_history = [
  "Appliance - Malfunction",
  "Bodily Injury",
  "Burglary",
  "Collapse - Above Ground Pool",
  "Collapse - Building",
  "Collapse - Inground Pool",
  "Collapse - Unspecified",
  "Collapse - Weight of Ice/Snow",
  "Consequential Loss",
  "Credit Card Forgery and Counterfeit Money",
  "Domestic Pets",
  "Earthquake",
  "Electrical Power Interruption/Power Surge",
  "Employee Fraud",
  "Entrapment",
  "Explosion - Electric Arcing",
  "Explosion - Propane/Natural Gas Appliance",
  "Explosion - Unspecified",
  "Falling Object",
  "Financial Fraud - Identity Theft",
  "Fire - Arson/Arson Suspected",
  "Fire-Careless Smoking",
  "Fire - Careless use of Candles/Matches",
  "Fire - Cooking Operations/Grease Fire",
  "Fire - Electrical",
  "Fire - Fireplace/Chimney",
  "Fire - Improper Storage & Handling of Chemicals/Fuels",
  "Fire - Portable Heater",
  "Fire - Unspecified",
  "Fire - Woodstove/Chimney",
  "Fire Department Charges",
  "Fire-Spread from Third Party Property",
  "Forgery",
  "Freezer Contents",
  "Fuel Leakage - Exterior Oil Storage Tank",
  "Fuel Leakage - Interior Oil Storage Tank",
  "Glass Breakage - Accidental",
  "Glass Breakage - Unspecified",
  "Glass Breakage - Vandalism",
  "Hail Damage",
  "Impact - Aircraft",
  "Impact - Land Vehicle, Insured Operator",
  "Impact - Land Vehicle, Third Party Operator",
  "Lightning - Antenna/Dish",
  "Lightning - Chimney",
  "Lightning - Electrical Equipment",
  "Lightning - Unspecified",
  "Loss Assessment Coverage",
  "Lost/Stolen Keys",
  "Mass Evacuation",
  "Mysterious Disappearance",
  "Pollution Damage",
  "Pollution Liability",
  "Prohibited Access by Civil Authority",
  "Property Damage",
  "Riot",
  "Robbery",
  "Sewer Backup",
  "Smoke - Fire in Adjacent Premises/Building",
  "Smoke - Heating/Ventilation Malfunction",
  "Smoke - Unspecified",
  "Tenants Legal Liability",
  "Theft/Attempted Theft - Off Premises, Non-vehicle",
  "Theft/Attempted Theft - Off Premises, Vehicle",
  "Theft/Attempted Theft - On Premises, Non-forced Entry",
  "Transportation - Collision/Upset/Overturn",
  "Transportation - Unspecified",
  "Vandalism/Malicious Damage - Building Vacant",
  "Vandalism/Malicious Damage - Explosion",
  "Vandalism/Malicious Damage - Illegal Substance Cultivation & Manufacturing",
  "Vandalism/Malicious Damage - Insured Suspect",
  "Vandalism/Malicious Damage - Unspecified",
  "Voluntary Compensation",
  "Voluntary Medical Payment",
  "Voluntary Property Damage",
  "Water Damage - Ice Build-up on Roof",
  "Water Damage - Infiltration",
  "Water Damage - Unspecified",
  "Water Escape/Rupture - Building",
  "Water Escape/Rupture - Contents",
  "Water Escape/Rupture - Eavestrough or Downspout",
  "Water Escape/Rupture - Freezing Building",
  "Water Escape/Rupture - Freezing, Unheated Building",
  "Water Escape/Rupture - Plumbing System",
  "Water Escape/Rupture - Seepage/Leakage",
  "Water Escape/Rupture - Sewer/Drain",
  "Water Escape/Rupture - Sprinkler System",
  "Water Escape/Rupture - Sump Pump Failure",
  "Water Escape/Rupture - Swimming Pool/Equipment",
  "Water Escape/Rupture - Unspecified",
  "Water Escape/Rupture - Waterbed",
  "Windstorm Damage",
];

export const reason_for_ending = [
  "Cancelled by Insurer",
  "Declined by Insurer",
  "Cancelled by Insured",
  "Non-Renewed by Insurer",
  "Non-Renewed by Insured",
  "Insurer Restricted Coverage",
];

export const terminated_by_insurer = [
  "Insurance Fraud",
  "Loss History",
  "Material Misrepresentation",
  "No Prior Insurance",
  "Non-disclosure",
  "Non-payment",
];

export const cross_ref_information = [
  "Private Passenger Auto",
  "Commercial Autos, Fleets, Trucks",
  "Farm Owners, Fire, Liability",
  "Habitational",
  "Miscellaneous Commercial Lines",
  "Miscellaneous Life and Health",
  "Miscellaneous Personal Lines",
  "Package Policy",
  "Personal Lines Umbrella",
];

export const coverage_claim_paid_under = [
  "Bodily Injury",
  "Property Damage",
  "Accident Benefits",
  "Direct Compensation - Property Damage",
  "Uninsured Automobile",
  "Coll-Collision/All Perils",
  "Comprehensive/Specified Perils",
];

export const policies_coverage_type = [
  "Term",
  "Whole Life",
  "Critical Illness",
  "Disability",
  "Long Term Care",
];

export const conviction_code = [
  "99999 Other (Attach SRMK-Remarks Group)",
  "ACCOM Excess blood alcohol while accomp. class G1 driver",
  "AIIP Alcohol Ignition Interlock Program",
  "ALC Blood Alcohol over .08",
  "ALCOP Open liquor in vehicle",
  "BACK Improper backing-up",
  "BIKE Interfering with bicyclist",
  "BL Improper driving in a Bus Lane",
  "BUS Public vehicle infraction",
  "CC Careless driving (Highway Traffic Act - Alberta only)",
  "CD Careless driving, without due care or attention (Criminal C)",
  "CN Criminal Negligence",
  "CPV Cellular Phone Violation",
  "DAB License class (restricted) drive with alcohol in blood",
  "DANGR Dangerous goods transport",
  "DB Defective Brakes",
  "DBW Driving on a bet or wager",
  "DD Dangerous Driving",
  "DDC Driving during curfew",
  "DEP License class (restricted) drive with excess passengers",
  "DFPB License class G1 accomp. driver - failrefuse provide breatt",
  "DFSP Class G1 drive with front seat passenger",
  "DFTI License class G1/G2 accompanying driver - failure to identify",
  "DH Improper use of divided highway",
  "DITA Driver has been involved in traffic accident (non-chargeable)",
  "DLC Drive left of centre",
  "DMP Class M1 drive motorcycle with passenger",
  "DMPH Class M1 drive motorcycle on prohibited highway",
  "DMU Driving motorcycle - usage violation",
  "DOSW Drive on sidewalk/footpath/ditch/median/closed road",
  "DPH Class G1 drive on prohibited highway",
  "DRD Driver distraction",
  "DS Crowding Drivers Seat",
  "DSV Drive a suspended vehicle (fail to meet safety requirements)",
  "DUH Glass G1/M1 drive at unlawful hour",
  "DUQD Class G1 drive unaccompanied by qualified driver",
  "DUS Driving while under suspension",
  "DWA Driving while Unauthorized",
  "DWID Driving without an interlock device",
  "EPH Entering prohibited highway",
  "EPHRV Drive recreational vehicle on prohibited roadway",
  "EXSPD Exceed speed for established time period",
  "FALST False statement re insurance",
  "FCIC Failure to carry or have insurance card",
  "FCRMS Failure to complete remedial programme FDH Failure to report damage to highway property FDP Failure to disclose particulars",
  "FP (Use code: 'FTSPO' instead)",
  "FPBT Failure to pass breath or blood test",
  "FPEI Failure to produce evidence of insurance",
  "FPHS Failure to proceed onto highway safely FPRV Failure to produce - recreational vehicle FRA Failing to report accident",
  "FRSL Failure or refusal to surrender licence",
  "FSCEV Failure to slow/change lane for stationary emergency vehicle FSLC Failure to stop at level crossing when driving school bus",
  "FSR Failing to share road",
  "FTC Following too closely",
  "FTGN Failure to give name/address information",
  "FTPA Failure to provide access",
  "FTPP Failure to permit passing",
  "FTR Failing to remain",
  "FTRA Failure to render all possible assistance",
  "FTS Failing to signal",
  "FTSLS Failure to stop for police resulting in license suspension",
  "FTSPO Failure to stop at the request of a police officer",
  "FTSSA Failure to stop at the scene of an accident",
  "FTSTP Failure to stop",
  "FTT Failure to turn",
  "FTY Failing to yield",
  "G1VL License class G1 violation",
  "G2VL License class G2 violation",
  "HELMT Failure of motorcyclist to wear helmet",
  "HL Headlight Offenses",
  "ILC Improper Lane change",
  "IMP Impaired driving",
  "IMPRU Imprudent Driver",
  "INS *Formerly Insurance Offence 'Use Specific Code'",
  "INSRV No insurance - recreational vehicle",
  "IOD Improper opening of door",
  "IP Improper passing",
  "ISBV Insufficient space between vehicles (if no accident ensues) IT Improper turn",
  "IUEE Improper use of emergency equipment or markings",
  "LD Insecure load",
  "LEFT Drive commercial vehicle in the left lane",
  "LIC Drivers License Violations",
  "LICPL License plate offence",
  "M1VL License class M1 violation",
  "M2VL License class M2 violation",
  "MAJOR Major Conviction (not specified)",
  "MINOR Minor Conviction (not specified) MKFS Make a false statement",
  "MM Motor manslaughter",
  "MOPED Motor-assisted bicycle infraction",
  "NDICL No driver's license or improper class of driver's license",
  "NDRV No driver's license - recreational vehicle",
  "NODRI Novice driver fail/refuse breath sample",
  "NSPP No special permit or placard",
  "OLO Overload",
  "OMVNI Operate motor vehicle - no insurance",
  "OT Obstructing traffic",
  "OW Wrong way on a one-way",
  "PARK Parking, standing or stopping",
  "PERNO Permit novice drive in contravention of restrict.",
  "PFI Produce false insurance",
  "PRE Produce false evidence",
  "PROC Intersect procession in motion",
  "PSB School bus - improper passing or failure to stop",
  "PSG School playground - improper passing",
  "PUT Prohibited use of tunnel by a vehicle carrying dangerous substances",
  "PX Fail to yield to pedestrian",
  "RAC Racing",
  "RADR Radar warning device in the vehicle",
  "RB Refuse breathalyzer",
  "REUV Refusal examination of an unsafe vehicle",
  "RI Reinstated",
  "RRX Railway crossing",
  "SB Seat belt violation",
  "SBP Driver failure to ensure a passenger under 16 yr/23 kg secured",
  "SCBUS School bus infractions",
  "SCS School crossing stop signal-failure to obey",
  "SD Unnecessarily slow driving",
  "SECTD Serious Conviction (not specified)",
  "SOLIC Soliciting offence",
  "SP Speeding (enter the number of km over the limit) SPEED Speeding-in alley/crown property/flashing light SPOVR Speeding over (Company Major Conviction)",
  "SS Stop sign",
  "SSZ Speed-school zone/playground",
  "STN Stunting",
  "SUP Suspended (enter the number of months suspended) SUP Suspendu (entrer le nombre de mois de suspension) SUPLF Suspended for life",
  "TLV Traffic Lane violation TP Trailer passenger",
  "TRLR Trailer - offence regarding",
  "TS Disobey traffic sign or signal",
  "TSL (Use code: 'TS' instead)",
  "TW Improper towing, persons on sled, bicycle, etc. UDW Unsafe Driving due to weather/road conditions UM Unsafe move",
  "UN Unnecessary noise",
  "UT Unsafe or prohibited turn",
  "UV Unsafe Vehicle",
  "VIV Vehicle inspection",
  "VOWI Vehicle owner without insurance",
  "VRO Vehicle Registration Offense",
  "WIN View obstructed",
  "XOVER Pedestrian violation",
];

export const coverage_type_one = [
  "Cross Liability",
  "Waiver of Subrogation",
  "Pollution Liability Extension",
  "Broad Form - Blanket Over Complex",
  "Replacement Cost - Same Site Clause Deleted",
  "Stated Amount Co-Insurance",
  "Building By Law-Extension",
];

export const coverage_type_four = [
  "Actual Loss Sustained",
  "Profits (Board/Named)",
  "Gross Earnings - Mercantile or Non-Manufacturing",
  "Gross Earnings - Manufacturing Business Income",
  "Extended Business Income",
  "Extended Rental Income",
  "Gross Rentals",
  "Extra Expense",
];

export const coverage_type_five = [
  "Contractor's Equipment",
  "Equipment Floater",
  "Tools Floater",
  "Installation Floater",
  "Sign Floater (Board)",
  "Exhibition Floater",
  "Fine Arts Floater (Board)",
  "Miscellaneous Property Floater",
];

export const machinery_breakdown = [
  "Machinery Breakdown/Boiler & Machinery",
  "Business Interruption (Actual Loss)",
  "Business Interruption (Loss of Profit)",
  "Business Interruption (Gross Earnings)",
  "Business Interruption (Rent or Rental Value)",
  "Business Interruption (Extra Expense)",
];

export const crime_insurance_coverage = [
  "Comprehensive 3D",
  "Employee Dishonesty Coverage Form A",
  "Loss Inside the Premises Coverage",
  "Loss Outside the Premises Coverage",
  "Money & Securities",
  "Money Orders & Counterfeit Paper Currency Coverage",
  "Depositor's Forgery Coverage",
];

export const interested_interest = [
  "1st Mortgagee",
  "2nd Mortgagee",
  "3rd Mortgagee",
  "Additional Insured",
  "Additional Named Insured",
  "First Mortgagee",
  "First Mortgagee and Additional Insured",
  "First Mortgagee and First Loss Payee",
  "Landlord",
  "Lessor",
  "Lienholder",
  "Loss Payee",
  "Mortgagee",
  "NA",
];

export const detached_structure_type = [
  "Barn",
  "Boathouse",
  "Chicken Coop",
  "Cottage",
  "Garage",
  "Greenhouse",
  "Sugar Camp",
  "Servant's Quaters",
  "Storage",
  "Stable",
  "Toolshed",
];
