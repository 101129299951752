import React from "react";
import { CustomChip } from "../CustomChip";

interface IActiveInActiveStatusProps {
  status: string;
}

export const StatusRenderer: React.FC<IActiveInActiveStatusProps> = (
  props
) => {
  const status = (props.status || "").toLowerCase();
  if (status === "active") {
    return <CustomChip color="success" content={"Active"} size="small" />;
  }
  else if(status === "open"){
    return <CustomChip color="warning" content={"Open"} size="small" />;
  }
  else if(status === "closed"){
    return <CustomChip color="error" content={"Closed"} size="small" />;
  }
  else if(status === "re-opened"){
    return <CustomChip color="info" content={"Re-Opened"} size="small" />;
  }
  else if (status === "Inactive"){
  return <CustomChip color="error" content={"Inactive"} size="small" />;
  }
  else if (status === "new submission"){
    return <CustomChip color="success" content={"New Submission"} size="small" />;
    }
    else if (status === "quote received"){
      return <CustomChip color="warning" content={"Quote Received"}  sx={{color: '#000'}}size="small" />;
      }
  else {
    return <CustomChip color="info" content={status || 'N/A'} size="small" />;
  }
};
