import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layout/fullLayout/loadable/loadable";
import { OOPSError } from "../views/authentication/OopsError";
import { AuthorizedRoute } from "./AuthorizedRoute";
import { ProtectedRoute } from "./ProtectedRoutes";
import { PublicRoute } from "./PublicRoute";
import { BeforeProfile } from "../views/Profile/UsersList/UsersList";
import { MODULE_IDS } from "../constants/enums";

/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() =>
    import("../layout/fullLayout/FullLayout").then(({ FullLayout }) => ({
      default: FullLayout,
    }))
  )
);
const BlankLayout = Loadable(
  lazy(() => import("../layout/blankLayout/BlankLayout"))
);
/* ***End Layouts**** */

const Error = Loadable(
  lazy(() =>
    import("../views/authentication/Error").then(({ Error }) => ({
      default: Error,
    }))
  )
);

/* ****Pages***** */

const Dashboard = Loadable(
  lazy(() =>
    import("../views/dashboard/Dashboard/Dashbaord").then(({ Dashboard }) => ({
      default: Dashboard,
    }))
  )
);

const Login = Loadable(
  lazy(() =>
    import("../views/authentication/Login").then(({ Login }) => ({
      default: Login,
    }))
  )
);
const AccessDenied = Loadable(
  lazy(() =>
    import("../views/authentication/AccessDenied").then(({ AccessDenied }) => ({
      default: AccessDenied,
    }))
  )
);


const CustomersList = Loadable(
  lazy(() =>
    import("../views/customers/CustomersList").then(({ CustomersList }) => ({
      default: CustomersList,
    }))
  )
);

// artist

const ArtistList = Loadable(
  lazy(() =>
    import("../views/artist/ArtistList").then(({ ArtistList }) => ({
      default: ArtistList,
    }))
  )
);
const ArtistProfile = Loadable(
  lazy(() =>
    import("../views/artist/UpdateArtistProfile").then(({ UpdateArtistProfile }) => ({
      default: UpdateArtistProfile,
    }))
  )
);
// category

const CategoryList = Loadable(
  lazy(() =>
    import("../views/category/CategoryList").then(({ CategoryList }) => ({
      default: CategoryList,
    }))
  )
);

const ManageUser = Loadable(
  lazy(() =>
    import("../views/Profile/UsersList/ManageUser").then(({ ManageUser }) => ({
      default: ManageUser,
    }))
  )
);
// data management

const UserBranchList = Loadable(
  lazy(() =>
    import("../views/dataManagement/userBranch/UserBranchList").then(({ BranchList }) => ({
      default: BranchList,
    }))
  )
);

const ManageUserBranch = Loadable(
  lazy(() =>
    import("../views/dataManagement/userBranch/ManageUserBranch").then(({ ManageUserBranch }) => ({
      default: ManageUserBranch,
    }))
  )
);
// playlist

const PlaylistList = Loadable(
  lazy(() =>
    import("../views/playlist/PlaylistList").then(({ PlaylistList }) => ({
      default: PlaylistList,
    }))
  )
);

const ManagePlaylist = Loadable(
  lazy(() =>
    import("../views/playlist/ManagePlaylist").then(({ ManagePlaylist }) => ({
      default: ManagePlaylist,
    }))
  )
);
// cron

const CronList = Loadable(
  lazy(() =>
    import("../views/cron/CronList").then(({ CronList }) => ({
      default: CronList,
    }))
  )
);

const ManageCron = Loadable(
  lazy(() =>
    import("../views/cron/ManageCron").then(({ ManageCronDialog }) => ({
      default: ManageCronDialog,
    }))
  )
);

const ViewProfile = Loadable(
  lazy(() =>
    import("../views/Profile/ViewProfile/ViewProfile").then(
      ({ ViewProfile }) => ({
        default: ViewProfile,
      })
    )
  )
);
const MyProfileView = Loadable(
  lazy(() =>
    import("../views/Profile/UsersList/MyProfileView").then(
      ({ MyProfileView }) => ({
        default: MyProfileView,
      })
    )
  )
);

// security
const ListSecurityGroups = Loadable(
  lazy(() =>
    import("../views/Security/ListSecurityGroups").then(
      ({ ListSecurityGroups }) => ({ default: ListSecurityGroups }),
    ),
  ),
);

const SecurityDashboard = Loadable(
  lazy(() =>
    import("../views/Security/CreateSecurityGroup").then(
      ({ CreateSecurityGroup }) => ({ default: CreateSecurityGroup })
    )
  )
);


const ForgotPassword = Loadable(
  lazy(() =>
    import("../views/authentication/ForgotPassword").then(
      ({ ForgotPassword }) => ({
        default: ForgotPassword,
      }),
    ),
  ),
);


export const Router = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <FullLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/",
        exact: true,
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "/dashbaord",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <Dashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/category",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <CategoryList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/artist",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <ArtistList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/artist-profile/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <ArtistProfile />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/view-customers",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <CustomersList />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/view-profile",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <ViewProfile />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users/manage",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <ManageUser />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <BeforeProfile />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/users/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <ManageUser />
          </AuthorizedRoute>
        ),
      },

      // data magement
      {
        path: "/user-branch",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <UserBranchList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/user-branch/manage",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <ManageUserBranch />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/user-branch/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <ManageUserBranch />
          </AuthorizedRoute>
        ),
      },

      {
        path: "/my-profile/:uuid",
        exact: true,
        element: (
          <ProtectedRoute>
            <MyProfileView />
          </ProtectedRoute>
        ),
      },
      // playlist
      {
        path: "/playlist",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <PlaylistList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/playlist/manage",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <ManagePlaylist />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/playlist/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <ManagePlaylist />
          </AuthorizedRoute>
        ),
      },

      // cron
      {
        path: "/cron",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <CronList />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/cron/manage",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <ManageCron />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/cron/manage/:uuid",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <ManageCron />
          </AuthorizedRoute>
        ),
      },
      // security
      {
        path: "/list-security-groups",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <ListSecurityGroups />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-security-group",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <SecurityDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/create-security-group/:roleId",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <SecurityDashboard />
          </AuthorizedRoute>
        ),
      },
      {
        path: "/secuirty-role-duplicate/:roleId",
        exact: true,
        element: (
          <AuthorizedRoute allowedRoles={["ADMIN"]}>
            <SecurityDashboard isDuplicate />
          </AuthorizedRoute>
        ),
      },

      { path: "access", element: <AccessDenied /> },
      { path: "error", element: <OOPSError /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },

  {
    path: "/auth",
    element: (
      <PublicRoute>
        <BlankLayout />
      </PublicRoute>
    ),
    children: [
      { path: "404", element: <Error /> },
      { path: "login", element: <Login /> },
      { path: "reset-password", element: <ForgotPassword /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];
