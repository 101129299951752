import { action } from "typesafe-actions";
import {
  IGeneralHistory,
  IMail,
  IPolicies,
  IRecordCount,
} from "./common.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { showMessage } from "../messages/messagesActions";
import { api } from "../../api/api";
import { LoadState } from "../../constants/enums";


export const FETCH_POLICIES_PROGRESS = "FETCH_POLICIES_PROGRESS";
export const FETCH_POLICIES_SUCCESS = "FETCH_POLICIES_SUCCESS";
export const FETCH_POLCIES_FAILED = "FETCH_POLCIES_FAILED";

export const fetchPoliciesProgress = () => action(FETCH_POLICIES_PROGRESS);
export const fetchPoliciesSuccess = (policies: IPolicies[]) =>
  action(FETCH_POLICIES_SUCCESS, { policies });
export const fetchPoliciesFailed = () => action(FETCH_POLCIES_FAILED);
export const fetchPolicies =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    const policies = getState().common.policies;
    if (policies.loadState !== LoadState.Loaded) {
      try {
        dispatch(fetchPoliciesProgress());
        const res = await api.get(`/policy/get-policies`);
        const data: IPolicies[] = res.data.data;

        dispatch(fetchPoliciesSuccess(data));
      } catch (err: any) {
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
        dispatch(fetchPoliciesFailed());
      }
    }
  };

export const fetchInitialRefData =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(fetchPolicies());
  };



export const sendMessageToUserPhone =
  (
    data: {
      mobileNumber: string;
      message: string;
    },
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      await api.post(`/general/send-sms`, {
        recipients: [
          {
            to: "+1" + data.mobileNumber,
            name: data.message,
          },
        ],
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Message send successfully!",
          displayAs: "snackbar",
        })
      );

      onCallback(true);
    } catch (err: any) {
      onCallback(true);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const sendMessageToWhatsapp =
  (
    data: {
      mobileNumber: string;
      message: string;
    },
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      await api.post(`/general/send-whatsapp`, {
        recipients: [
          {
            to: "+1" + data.mobileNumber,
            name: data.message,
          },
        ],
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Message send successfully!",
          displayAs: "snackbar",
        })
      );

      onCallback(true);
    } catch (err: any) {
      onCallback(true);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const sendVoiceCalling =
  (
    data: {
      mobileNumber: string;
    },
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      await api.post(`/general/voice-calling`, {
        recipients: [
          {
            to: "+1" + data.mobileNumber,
          },
        ],
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Voice calling is send successfully!",
          displayAs: "snackbar",
        })
      );

      onCallback(true);
    } catch (err: any) {
      onCallback(true);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const sendEmail =
  (
    data: IMail,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      await api.post(`/general/send-email`, {
        ...data,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Email is send successfully!",
          displayAs: "snackbar",
        })
      );

      onCallback(true);
    } catch (err: any) {
      onCallback(true);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const FETCH_GENERAL_HISTORY_LIST_PROGRESS =
  "FETCH_GENERAL_HISTORY_LIST_PROGRESS";
export const FETCH_GENERAL_HISTORY_LIST_SUCCESS =
  "FETCH_GENERAL_HISTORY_LIST_SUCCESS";
export const FETCH_GENERAL_HISTORY_LIST_FAILED =
  "FETCH_GENERAL_HISTORY_LIST_FAILED";

export const fetchGeneraHistoryListProgress = () =>
  action(FETCH_GENERAL_HISTORY_LIST_PROGRESS);
export const fetchGeneraHistoryListSuccess = (data: IGeneralHistory[]) =>
  action(FETCH_GENERAL_HISTORY_LIST_SUCCESS, { data });
export const fetchGeneraHistoryListFailed = () =>
  action(FETCH_GENERAL_HISTORY_LIST_FAILED);

export const fetchGeneraHistoryListAsync =
  (
    customerPolicyId: number,
    tableName: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchGeneraHistoryListProgress());
      const res = await api.get(
        `/general/get-log-data?table_name=${tableName}&customer_policy_id=${customerPolicyId}`
      );

      const data: IGeneralHistory[] = res.data.data;
      if (data) {
        dispatch(fetchGeneraHistoryListSuccess(data));
      } else {
        dispatch(fetchGeneraHistoryListSuccess([]));
      }
    } catch (err: any) {
      dispatch(fetchGeneraHistoryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_GENERAL_HISTORY_STATE = "CLEAR_GENERAL_HISTORY_STATE";

export const clearGeneraHistoryState = () =>
  action(CLEAR_GENERAL_HISTORY_STATE);

export const FETCH_RECORD_COUNTS_PROGRESS = "FETCH_RECORD_COUNTS_PROGRESS";
export const FETCH_RECORD_COUNTS_SUCCESS = "FETCH_RECORD_COUNTS_SUCCESS";
export const FETCH_RECORD_COUNTS_FAILED = "FETCH_RECORD_COUNTS_FAILED";

export const fetchRecordsCountsProgress = () =>
  action(FETCH_RECORD_COUNTS_PROGRESS);
export const fetchRecordsCountsSuccess = (data: IRecordCount[]) =>
  action(FETCH_RECORD_COUNTS_SUCCESS, { data });
export const fetchRecordsCountsFailed = () =>
  action(FETCH_RECORD_COUNTS_FAILED);

export const fetchRecordCountsAsync =
  (
    tableName: string,
    column: string,
    fromDate: string,
    toDate: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchRecordsCountsProgress());
      const res = await api.get(
        `/general/get-record-counts?table_name=${tableName}&column=${column}&from_date=${fromDate}&to_date=${toDate}`
      );

      const data: IRecordCount[] = res.data.data;

      dispatch(fetchRecordsCountsSuccess(data || []));
    } catch (err: any) {
      dispatch(fetchRecordsCountsFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        })
      );
    }
  };

export const CLEAR_RECORD_COUNTS = "CLEAR_RECORD_COUNTS";
export const clearRecordCounts = () => action(CLEAR_RECORD_COUNTS);
