import React from "react";
import { api } from "../../api/api";
import LinearProgress from "@mui/material/LinearProgress";

export enum userRoles {
  ADMIN = "ADMIN",
  None = "None",
}

export interface IAuth {
  user: {
    name: string;
    userId: number;
    email: string;
    isLogin: boolean;
    role: keyof typeof userRoles;
    tokens: { access: string } | null;
    userUuid: string;
  };
  twoFactorData: {
    userFactId: string;
  };
  login: (
    email: string,
    password: string,
    onSuccess: (isOTPPreview: boolean) => void,
    onError: (message: string) => void
  ) => void;
  resendOTP: () => void;
  verifyOTP: (
    otp: string,
    onSuccess: () => void,
    onError: (message: string) => void
  ) => void;
  forgotPassword: (
    email: string,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => void;
  setNewPassword: (
    email: string,
    otp: number,
    user_password: string,
    onSuccess: () => void,
    onError: (message: string) => void
  ) => void;
  logout: () => void;
  addUserName: (name: string) => void;
}

const AuthContext = React.createContext<IAuth>({
  user: {
    name: "",
    email: "",
    userId: 0,
    role: userRoles.None,
    isLogin: false,
    tokens: null,
    userUuid: "",
  },
  twoFactorData: {
    userFactId: "",
  },
  login: () => { },
  resendOTP: () => { },
  verifyOTP: () => { },
  forgotPassword: () => { },
  setNewPassword: () => { },
  logout: () => { },
  addUserName: () => { },
});

export const useAuth = () => React.useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [user, setUser] = React.useState<IAuth["user"]>({
    name: "",
    email: "",
    role: userRoles.None,
    isLogin: false,
    tokens: null,
    userId: 0,
    userUuid: "",
  });

  const [twoFactorData, setTwoFactorData] = React.useState<
    IAuth["twoFactorData"]
  >({
    userFactId: "",
  });
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const data = JSON.parse(auth);
      const date1 = new Date(data.date);
      const date2 = new Date();
      const diff = date2.getHours() - date1.getHours();
      if (diff <= 1) {
        setUser(data);
      }
    }
    setLoading(false);
  }, []);

  const handleLogin = async (
    email: string,
    password: string,
    onSuccess: (isOTPPreview: boolean) => void,
    onError: (message: string) => void
  ) => {
    try {
      const res = await api.post("/authentication/login", {
        email: email,
        password: password,
      });
      const data = res.data;

      const userData = {
        name: email, //user.user_name,
        email: email, //user.email,
        role: userRoles.ADMIN,
        isLogin: data.data.isOTPPreview === false ? true : false, //if isOTPPreview false then user should login without otp
        userId: data.data.userFactId,
        tokens: data.data.token, //res.data.Tokens,
        userUuid: data.data.user.user_uuid
      };
      setUser(userData);
      setPassword(password)
      onSuccess(!userData.isLogin);
      if (userData.isLogin) {
        localStorage.setItem(
          "auth",
          JSON.stringify({ ...userData, date: new Date() })
        );
      } else {
        setTwoFactorData({ userFactId: data.data.userFactId });
      }

    } catch (error: any) {
      onError(error.response.data.message);
    }
  };

  const resendOTP = async () => {
    try {
      await api.post("/authentication/login", {
        email: user.email,
        password: password,
      });
    }
    catch (error: any) {
      console.log(error)
    }
  }

  const handleVerfiyOTP = async (
    otp: string,
    onSuccess: () => void,
    onError: (message: string) => void
  ) => {
    try {
      const res = await api.post("/user/validate-otp-get-token", {
        user_fact_id: twoFactorData.userFactId,
        otp: Number(otp),
      });
      const data = res.data;

      const userData = {
        ...user,
        isLogin: true,
        tokens: { access: data.data.access_token },
      };
      setUser(userData);
      localStorage.setItem(
        "auth",
        JSON.stringify({ ...userData, date: new Date() })
      );
      onSuccess();
    } catch (error: any) {
      onError(error.response.data.message);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    // setUser({
    //   isLogin: false,
    //   name: "",
    //   email: "",
    //   tokens: null,
    //   role: userRoles["None"],
    // });
    // dispatch({ type: USER_LOGOUT });
    // navigate("/auth/login");
    window.open("/auth/login", "_self");
  };

  const handleForgotPassword = async (
    email: string,
    onSuccess: () => void,
    onError: (message: string) => void
  ) => {
    try {
      await api.post("/authentication/forget-password", {
        email: email,
        otp: 0,
        user_password: null,
        action: "SEND",
      });
      onSuccess();
    } catch (error: any) {
      onError(error.response.data.message);
    }
  };

  const handleNewPassword = async (
    email: string,
    otp: number,
    user_password: string,
    onSuccess: () => void,
    onError: (message: string) => void,
  ) => {
    try {
      await api.post("/authentication/forget-password", {
        email: email,
        otp: otp,
        user_password: user_password,
        action: "MATCH",
      });
      onSuccess();
    } catch (error: any) {
      onError(error.response.data.message);
    }
  }

  const addUserName = (name: string) => {
    setUser({ ...user, name: name });
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <AuthContext.Provider
      value={{
        user: user,
        login: handleLogin,
        twoFactorData,
        verifyOTP: handleVerfiyOTP,
        forgotPassword: handleForgotPassword,
        setNewPassword: handleNewPassword,
        logout: handleLogout,
        resendOTP: resendOTP,
        addUserName: addUserName,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
