import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../initialStoreState";

import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { showMessage } from "../messages/messagesActions";
import { IUser, IUserCreation } from "./userProfile.types";

export const FETCH_USERS_LIST_PROGRESS = "FETCH_USERS_LIST_PROGRESS";
export const FETCH_USERS_LIST_SUCCESS = "FETCH_USERS_LIST_SUCCESS";
export const FETCH_USERS_LIST_FAILED = "FETCH_USERS_LIST_FAILED";

export const fetchUsersListProgress = () => action(FETCH_USERS_LIST_PROGRESS);
export const fetchUsersListSuccess = (
  data: IUser[],
  totalNumberOfRecords: number,
) => action(FETCH_USERS_LIST_SUCCESS, { data: data, totalNumberOfRecords });
export const fetchUsersListFailed = () => action(FETCH_USERS_LIST_FAILED);

export const fetchUsersListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchUsersListProgress());
        const searchQuery = getSearchQuery(queryParams);
        const res = await api.get(`/user/get-user${searchQuery}`);

        const data: IUser[] = res.data.data;
        dispatch(fetchUsersListSuccess(data, res.data.totalRecords));
      } catch (err: any) {
        dispatch(fetchUsersListFailed());
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const FETCH_USERS_PROGRESS = "FETCH_USERS_PROGRESS";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILED = "FETCH_USERS_FAILED";

export const fetchUsersProgress = () => action(FETCH_USERS_PROGRESS);
export const fetchUsersSuccess = (data: IUser) =>
  action(FETCH_USERS_SUCCESS, { data: data });
export const fetchUsersFailed = (errorMessage: string) =>
  action(FETCH_USERS_FAILED, { errorMessage });

export const fetchUsersAsync =
  (userId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        dispatch(fetchUsersProgress());
        const res = await api.get(
          `/user/get-user?user_uuid=${userId}`
        );
        const data = res.data.data;
        if (data) {
          dispatch(fetchUsersSuccess(data[0]));
        } else {
          dispatch(
            fetchUsersFailed(
              "Unfortunately, there are no records available at the moment."
            )
          );
        }
      } catch (err: any) {
        dispatch(fetchUsersFailed("Something went to be wrong!"));
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };

export const upsertUserAysnc =
  (
    user: Partial<IUser>,
    onCallback: (isSuccess: boolean) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const {
          email,
          role_id,
          role_name,
          user_profile_id,
          user_dim_id,
          user_password,
          role_value,
          role_uuid,
          full_name,
          role_group,
          rowId,
          create_ts,
          insert_ts,
          module_security,
          user_fact_id,
          ...rest
        } = user;

        await api.post("/user/update-profile", rest);

        dispatch(
          showMessage({
            type: "success",
            message: "User profile is updated successfully",
            displayAs: "snackbar",
          })
        );
        onCallback(true);
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };

export const createNewUserAsync =
  (
    user: IUserCreation,
    onCallback: (isSuccess: boolean, user_fact_id?: number) => void
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        const res = await api.post("/user/upsert-user", user);

        dispatch(
          showMessage({
            type: "success",
            message: "User is created successfully",
            displayAs: "snackbar",
          })
        );
        onCallback(true, res.data.data.user_fact_id);
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          })
        );
      }
    };

// *************reset password actions *******************
export const generateOTP =
  (
    email: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        await api.post("/authentication/generate-otp", {
          email: email,
          otp_for: "FORGET_PASSWORD",
        });

        dispatch(
          showMessage({
            type: "success",
            message: "OTP send successfully.",
            displayAs: "snackbar",
          }),
        );
        onCallback(true);
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

export const changeForgotPassword =
  (
    data: {
      email: string;
      otp: string;
      user_password: string;
      action: string;
    },
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        await api.post("/authentication/forget-password", data);

        dispatch(
          showMessage({
            type: "success",
            message: "Password changed successfully.",
            displayAs: "snackbar",
          }),
        );
        onCallback(true);
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };
export const changePasswordByAdmin =
  (
    data: {
      user_uuid: string;
      user_password: string;
    },
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
    async (dispatch, getState) => {
      try {
        await api.post("/user/change-user-password", data);

        dispatch(
          showMessage({
            type: "success",
            message: "Password changed successfully.",
            displayAs: "snackbar",
          }),
        );
        onCallback(true);
      } catch (err: any) {
        onCallback(false);
        dispatch(
          showMessage({
            type: "error",
            message: err.response.data.message,
            displayAs: "snackbar",
          }),
        );
      }
    };

