import produce from "immer";
import { CategoryActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { CLEAR_CATEGORY, CLEAR_CATEGORY_STATE, FETCH_CATEGORY_LIST_FAILED, FETCH_CATEGORY_LIST_PROGRESS, FETCH_CATEGORY_LIST_SUCCESS } from "./categoryActions";
import { initialCategory, initialCategoryState } from "./defaultState";



export const categoryReducer = (
  state: IStoreState["category"] = initialCategoryState,
  action: CategoryActions,
) => {
  switch (action.type) {
    case FETCH_CATEGORY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CATEGORY_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CATEGORY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case CLEAR_CATEGORY: {
      const newState = produce(state, (draftState) => {
        draftState.singleCategory.loading = LoadState.NotLoaded;
        draftState.singleCategory.data = initialCategory;
      });
      return newState;
    }
    case CLEAR_CATEGORY_STATE: {
      return initialCategoryState;
    }
    default: {
      return state;
    }
  }
};
