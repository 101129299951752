import React from "react";
import { useSelector } from "react-redux";
import { IStoreState } from "../../redux/initialStoreState";
import { Alert, IconButton, Snackbar } from "@mui/material";
import { useDispatchWrapper } from "../../hooks";
import { hideMessage } from "../../redux/messages/messagesActions";
import CloseIcon from "@mui/icons-material/Close";

export const MessageBox: React.FC = () => {
  const { item } = useSelector((storeState: IStoreState) => storeState.message);

  const dispatch = useDispatchWrapper();

  const handleClearMessage = () => {
    dispatch(hideMessage());
  };

  if (item) {
    if (item.displayAs === "snackbar") {
      return (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={true}
          autoHideDuration={5000}
          onClose={handleClearMessage}
          key={"topright"}
        >
          <Alert
            severity={item.type}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClearMessage}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {item.message}
          </Alert>
        </Snackbar>
      );
    }
  }
  return <></>;
};
