import produce from "immer";
import { PlaylistActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { initialPlaylist, initialPlaylistState } from "./defaultState";
import { CLEAR_PLAYLIST, CLEAR_PLAYLIST_STATE, FETCH_PLAYLIST_FAILED, FETCH_PLAYLIST_LIST_FAILED, FETCH_PLAYLIST_LIST_PROGRESS, FETCH_PLAYLIST_LIST_SUCCESS, FETCH_PLAYLIST_PROGRESS, FETCH_PLAYLIST_SUCCESS } from "./playlistActions";

export const playlistReducer = (
  state: IStoreState["playlist"] = initialPlaylistState,
  action: PlaylistActions,
) => {
  switch (action.type) {
    case FETCH_PLAYLIST_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PLAYLIST_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PLAYLIST_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_PLAYLIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.singlePlaylist.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PLAYLIST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.singlePlaylist.loading = LoadState.Loaded;
        draftState.singlePlaylist.data = data;
      });
      return newState;
    }
    case FETCH_PLAYLIST_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.singlePlaylist.loading = LoadState.Failed;
        draftState.singlePlaylist.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_PLAYLIST: {
      const newState = produce(state, (draftState) => {
        draftState.singlePlaylist.loading = LoadState.NotLoaded;
        draftState.singlePlaylist.data = initialPlaylist;
      });
      return newState;
    }
    case CLEAR_PLAYLIST_STATE: {
      return initialPlaylistState;
    }
    default: {
      return state;
    }
  }
};
